// https://mantine.dev/core/switch/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Switch as MantineSwitch,
  SwitchProps as MantineSwitchProps,
  SwitchStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';
import { cx } from '../../utils';

type SwitchClassNamesType = Partial<Record<SwitchStylesNames, string>>;

const styles: ClassArrayOrString<SwitchClassNamesType> = {};

export interface SwitchProps extends MantineSwitchProps {
  classNames?: SwitchClassNamesType;
  disabled?: boolean;
}

export const Switch = Object.assign(
  forwardRef<HTMLInputElement, SwitchProps>(
    ({ classNames, disabled = false, ...restProps }, ref) => {
      const mergedStyles = mergeMantineStyles(styles, classNames);

      return (
        // hack to add cursor pointer to label, unsupported by Mantine for Switch as of now
        <div
          className={cx(
            '[&>div>div>label]:cursor-pointer',
            disabled && '[&>div>div>label]:cursor-not-allowed'
          )}
        >
          <MantineSwitch ref={ref} classNames={mergedStyles} disabled={disabled} {...restProps} />
        </div>
      );
    }
  ),
  {
    Group: MantineSwitch.Group,
  }
);

export type { SwitchGroupProps } from '@mantine/core';
