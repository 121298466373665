// https://mantine.dev/core/loading-overlay/

import {
  // eslint-disable-next-line no-restricted-imports
  LoadingOverlay as MantineLoadingOverlay,
  LoadingOverlayProps as MantineLoadingOverlayProps,
  LoadingOverlayStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type LoadingOverlayClassNamesType = Partial<Record<LoadingOverlayStylesNames, string>>;

const styles: ClassArrayOrString<LoadingOverlayClassNamesType> = {};

export interface LoadingOverlayProps extends MantineLoadingOverlayProps {
  classNames?: LoadingOverlayClassNamesType;
}

const overlayProps = {
  radius: 'sm',
  blur: 2,
};

export const LoadingOverlay = ({ classNames, ...restProps }: LoadingOverlayProps) => {
  const mergedStyles = mergeMantineStyles(styles, classNames);

  return (
    <MantineLoadingOverlay classNames={mergedStyles} overlayProps={overlayProps} {...restProps} />
  );
};
