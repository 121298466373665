// https://mantine.dev/core/slider/#marks

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  RangeSlider as MantineRangeSlider,
  RangeSliderProps as MantineRangeSliderProps,
  SliderStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../../locals/types';
import { mergeMantineStyles } from '../../../locals/utils';

type SliderClassNamesType = Partial<Record<SliderStylesNames, string>>;

// these are the default styles
const styles: ClassArrayOrString<SliderClassNamesType> = {
  thumb: 'border-2 border-andisor-red',
  bar: 'bg-andisor-red',
  markLabel: 'mt-2',
  label: 'px-1 py-0.5',
};

export interface RangeSliderProps extends MantineRangeSliderProps {
  classNames?: SliderClassNamesType;
}

export const RangeSlider = forwardRef<HTMLDivElement, RangeSliderProps>(
  ({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return <MantineRangeSlider ref={ref} classNames={mergedStyles} {...restProps} />;
  }
);

export type { RangeSliderValue } from '@mantine/core';
