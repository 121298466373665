// https://mantine.dev/dates/date-picker-input/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  DatePickerInput as MantineDatePickerInput,
  DatePickerInputProps as MantineDatePickerInputProps,
  DatePickerInputStylesNames,
} from '@mantine/dates';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';
import { PopoverProps } from '../Popover/Popover';

type DateInputClassNamesType = Partial<Record<DatePickerInputStylesNames, string>>;

const styles: ClassArrayOrString<DateInputClassNamesType> = {
  input: 'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100 bg-white',
};

export interface DatePickerInputProps extends MantineDatePickerInputProps {
  classNames?: DateInputClassNamesType;
}

const popoverProps: PopoverProps = {
  withinPortal: false,
  classNames: {
    dropdown: 'p-1',
  },
};

export const DatePickerInput = forwardRef<HTMLButtonElement, DatePickerInputProps>(
  ({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return (
      // TODO: Investigate why removing the @ts-ignore causes a type error
      // @ts-ignore
      <MantineDatePickerInput
        ref={ref}
        classNames={mergedStyles}
        allowDeselect={false}
        popoverProps={popoverProps}
        {...restProps}
      />
    );
  }
);
