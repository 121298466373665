import { tv } from 'tailwind-variants';

// styles applied at root level
export const buttonRootStyles = tv({
  base: 'relative inline-flex w-fit cursor-pointer select-none items-center justify-center gap-2 rounded-[2.5rem] font-body transition-all duration-200',
  variants: {
    color: {
      // refer compound variants below
      red: '',
      blue: '',
      pink: '',
      navy: '',
    },
    variant: {
      filled: 'ring-0',
      outline: 'bg-transparent ring-1 ring-current',
      'outline-text-light': 'bg-transparent ring-1',
      'outline-text-dark': 'bg-transparent ring-1',
      plain: 'rounded-none bg-transparent ring-0',
    },
    size: {
      xs: 'px-2 py-0.5 text-xs',
      sm: 'px-4 py-1.5 text-sm',
      md: 'px-5 py-2.5 text-base',
    },
    disabled: {
      true: 'data-[disabled=true]:cursor-not-allowed',
    },
    loading: {
      true: 'data-[loading=true]:cursor-wait',
    },
  },
  compoundVariants: [
    // red compound variant
    {
      color: 'red',
      variant: 'filled',
      disabled: false,
      className: [
        'bg-andisor-red text-white',
        'hover:bg-andisor-red-800 hover:ring-1 hover:ring-andisor-red-300',
        'active:bg-andisor-red-800 active:ring-2 active:ring-andisor-red-300',
      ],
    },
    {
      color: 'red',
      variant: 'outline',
      disabled: false,
      className: [
        'text-andisor-red',
        'hover:bg-andisor-red/10',
        'active:bg-andisor-red/15 active:ring-2',
      ],
    },
    {
      color: 'red',
      variant: 'outline-text-light',
      disabled: false,
      className: [
        'text-white ring-andisor-red',
        'hover:bg-andisor-red/10',
        'active:bg-andisor-red/15 active:ring-2',
      ],
    },
    {
      color: 'red',
      variant: 'outline-text-dark',
      disabled: false,
      className: [
        'text-andisor-navy ring-andisor-red',
        'hover:bg-andisor-red/10',
        'active:bg-andisor-red/15 active:ring-2',
      ],
    },
    {
      color: 'red',
      variant: 'plain',
      disabled: false,
      className: 'text-andisor-red',
    },
    // blue compound variant
    {
      color: 'blue',
      variant: 'filled',
      disabled: false,
      className: [
        'bg-andisor-blue text-white',
        'hover:bg-andisor-blue-900 hover:ring-1 hover:ring-andisor-blue-300',
        'active:bg-andisor-blue-900 active:ring-2 active:ring-andisor-blue-300',
      ],
    },
    {
      color: 'blue',
      variant: 'outline',
      disabled: false,
      className: [
        'text-andisor-blue',
        'hover:bg-andisor-blue/10',
        'active:bg-andisor-blue/15 active:ring-2',
      ],
    },
    {
      color: 'blue',
      variant: 'outline-text-light',
      disabled: false,
      className: [
        'text-white ring-andisor-blue',
        'hover:bg-andisor-blue/10',
        'active:bg-andisor-blue/15 active:ring-2',
      ],
    },
    {
      color: 'blue',
      variant: 'outline-text-dark',
      disabled: false,
      className: [
        'text-andisor-navy ring-andisor-blue',
        'hover:bg-andisor-blue/10',
        'active:bg-andisor-blue/15 active:ring-2',
      ],
    },
    {
      color: 'blue',
      variant: 'plain',
      disabled: false,
      className: 'text-andisor-blue',
    },
    // pink compound variant
    {
      color: 'pink',
      variant: 'filled',
      disabled: false,
      className: [
        'bg-andisor-pink text-andisor-navy',
        'hover:bg-andisor-pink-600 hover:ring-1 hover:ring-andisor-pink-300',
        'active:bg-andisor-pink-600 active:ring-2 active:ring-andisor-pink-300',
      ],
    },
    {
      color: 'pink',
      variant: 'outline',
      disabled: false,
      className: [
        'text-andisor-pink',
        'hover:bg-andisor-pink/10',
        'active:bg-andisor-pink/15 active:ring-2',
      ],
    },
    {
      color: 'pink',
      variant: 'outline-text-light',
      disabled: false,
      className: [
        'text-white ring-andisor-pink',
        'hover:bg-andisor-pink/10',
        'active:bg-andisor-pink/15 active:ring-2',
      ],
    },
    {
      color: 'pink',
      variant: 'outline-text-dark',
      disabled: false,
      className: [
        'text-andisor-navy ring-andisor-pink',
        'hover:bg-andisor-pink/10',
        'active:bg-andisor-pink/15 active:ring-2',
      ],
    },
    {
      color: 'pink',
      variant: 'plain',
      disabled: false,
      className: 'text-andisor-pink',
    },
    // navy compound variant
    {
      color: 'navy',
      variant: 'filled',
      disabled: false,
      className: [
        'bg-andisor-navy text-white',
        'hover:bg-andisor-navy-800 hover:ring-1 hover:ring-andisor-navy-300',
        'active:bg-andisor-navy-800 active:ring-2 active:ring-andisor-navy-300',
      ],
    },
    {
      color: 'navy',
      variant: 'outline',
      disabled: false,
      className: [
        'text-andisor-navy',
        'hover:bg-andisor-navy/10',
        'active:bg-andisor-navy/15 active:ring-2',
      ],
    },
    {
      color: 'navy',
      variant: 'outline-text-light',
      disabled: false,
      className: [
        'text-white ring-andisor-navy',
        'hover:bg-andisor-navy/10',
        'active:bg-andisor-navy/15 active:ring-2',
      ],
    },
    {
      color: 'navy',
      variant: 'outline-text-dark',
      disabled: false,
      className: [
        'text-andisor-navy ring-andisor-navy',
        'hover:bg-andisor-navy/10',
        'active:bg-andisor-navy/15 active:ring-2',
      ],
    },
    {
      color: 'navy',
      variant: 'plain',
      disabled: false,
      className: 'text-andisor-navy',
    },
    // disabled styles
    {
      variant: ['filled', 'outline', 'outline-text-light', 'outline-text-dark'],
      disabled: true,
      className:
        'data-[disabled=true]:bg-andisor-gray-200 data-[disabled=true]:text-andisor-gray-500 data-[disabled=true]:ring-1 data-[disabled=true]:ring-andisor-gray-400',
    },
    {
      variant: 'plain',
      disabled: true,
      className: 'data-[disabled=true]:text-andisor-gray-500',
    },
  ],
  defaultVariants: {
    color: 'blue',
    variant: 'filled',
    size: 'md',
    disabled: false,
    loading: false,
  },
});

// styles applied at root level
export const actionIconRootStyles = tv({
  base: 'relative inline-flex select-none items-center justify-center font-body transition-all duration-200',
  variants: {
    color: {
      // refer compound variants below
      white: '',
      red: '',
      blue: '',
      pink: '',
      navy: '',
    },
    variant: {
      rounded: 'rounded-full',
      plain: 'rounded-none bg-transparent',
    },
    size: {
      lg: 'h-[4rem] w-[4rem]', // 64px
      md: 'h-[2.5rem] w-[2.5rem]', // 40px
      sm: 'h-[2rem] w-[2rem]', // 32px
    },
    disabled: {
      true: 'data-[disabled=true]:cursor-not-allowed',
    },
    loading: {
      true: 'data-[loading=true]:cursor-wait',
    },
  },
  compoundVariants: [
    // white compound variant
    {
      color: 'white',
      variant: 'rounded',
      disabled: false,
      className: [
        'bg-white text-andisor-navy',
        'hover:bg-andisor-navy-800 hover:text-white',
        'active:bg-andisor-navy-800',
      ],
    },
    {
      color: 'white',
      variant: 'plain',
      disabled: false,
      className: ['text-andisor-white', 'hover:text-andisor-blue', 'active:text-andisor-blue'],
    },
    // red compound variant
    {
      color: 'red',
      variant: 'rounded',
      disabled: false,
      className: [
        'bg-andisor-red text-white',
        'hover:bg-andisor-red-800',
        'active:bg-andisor-red-800',
      ],
    },
    {
      color: 'red',
      variant: 'plain',
      disabled: false,
      className: ['text-andisor-red', 'hover:text-andisor-red-800', 'active:text-andisor-red-800'],
    },
    // blue compound variant
    {
      color: 'blue',
      variant: 'rounded',
      disabled: false,
      className: [
        'bg-andisor-blue text-white',
        'hover:bg-andisor-blue-900',
        'active:bg-andisor-blue-900',
      ],
    },
    {
      color: 'blue',
      variant: 'plain',
      disabled: false,
      className: [
        'text-andisor-blue',
        'hover:text-andisor-blue-900',
        'active:text-andisor-blue-900',
      ],
    },
    // pink compound variant
    {
      color: 'pink',
      variant: 'rounded',
      disabled: false,
      className: [
        'bg-andisor-pink text-andisor-navy',
        'hover:bg-andisor-pink-600',
        'active:bg-andisor-pink-600',
      ],
    },
    {
      color: 'pink',
      variant: 'plain',
      disabled: false,
      className: [
        'text-andisor-pink',
        'hover:text-andisor-pink-600',
        'active:text-andisor-pink-600',
      ],
    },
    // navy compound variant
    {
      color: 'navy',
      variant: 'rounded',
      disabled: false,
      className: [
        'bg-andisor-navy text-white',
        'hover:bg-andisor-navy-800',
        'active:bg-andisor-navy-800',
      ],
    },
    {
      color: 'navy',
      variant: 'plain',
      disabled: false,
      className: [
        'text-andisor-navy',
        'hover:text-andisor-navy-800',
        'active:text-andisor-navy-800',
      ],
    },
    // disabled styles
    {
      variant: 'rounded',
      disabled: true,
      className:
        'data-[disabled=true]:bg-andisor-gray-200 data-[disabled=true]:text-andisor-gray-500 data-[disabled=true]:ring-1 data-[disabled=true]:ring-andisor-gray-400',
    },
    {
      variant: 'plain',
      disabled: true,
      className: 'data-[disabled=true]:text-andisor-gray-500',
    },
  ],
  defaultVariants: {
    color: 'white',
    variant: 'plain',
    size: 'md',
    disabled: false,
    loading: false,
  },
});
