import React from 'react';
import { ChangeEvent } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type RHFRadioGroupProps = {
  name: string;
  items: {
    id: number | string;
    name: string;
    description?: string;
  }[];
  valueKey?: 'id' | 'name';
  defaultValue?: string;
  disabled?: boolean;
};

const RHFRadioGroup = ({
  name,
  items,
  valueKey = 'id',
  defaultValue,
  disabled = false,
}: RHFRadioGroupProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          {items?.map(item => (
            <React.Fragment key={item.id}>
              <FormControlLabel
                label={item.name}
                disableTypography
                control={
                  <Radio
                    {...field}
                    checked={field.value === `${item[valueKey]}`}
                    value={item[valueKey]}
                    disabled={disabled}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      field.onChange(evt.target.value);
                    }}
                    onClick={() => {
                      if (field.value === `${item[valueKey]}`) {
                        // allow deselection
                        field.onChange('');
                      }
                    }}
                    inputProps={{ 'aria-label': item.name }}
                    style={{ color: '#171BD4', opacity: disabled ? 0.3 : 1 }}
                    size="small"
                  />
                }
              />
              {item.description && <p className="mb-2 text-xs">{item.description}</p>}
            </React.Fragment>
          ))}
        </>
      )}
    />
  );
};

export default RHFRadioGroup;
