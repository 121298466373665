import type { UseFormRegisterReturn } from 'react-hook-form';

import { ComponentProps } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { cx } from '@ui/utils';
//icons
// import { Info as InfoIcon } from "@mui/icons-material";
import { IconInfoCircleFilled } from '@ui/icons';
import ToolTip from '@components/ToolTip';

// infers all the types from cva, all optional
export type InputStyleProps = VariantProps<typeof InputStyles>;
export const InputStyles = cva(
  // styles all buttons will have
  'border-none focus:outline-none',
  {
    variants: {
      variant: {
        default: `w-full rounded bg-white py-[5px] px-2 ring-1 focus:ring-2
                ring-slate-300 placeholder:text-slate-400 focus:ring-blue-600 aria-[invalid=true]:focus:ring-rose-600 disabled:cursor-not-allowed`,
      },
    },
  }
);

type InputProps<TRegister extends string> = Omit<ComponentProps<'input'>, 'className'> & {
  register?: UseFormRegisterReturn<TRegister>;
  error: string | undefined | null;
  id?: string;
  ariaLabel?: string;
  label?: string;
  type?: 'text' | 'password' | 'number' | 'date';
  placeholder?: string;
  disabled?: boolean;
  autoComplete?: string;
  IconLeft?: React.ReactElement;
  IconRight?: any;
  IconLeftClassNames?: string;
  IconRightClassNames?: string;
  className?: (({ isError }: { isError: boolean }) => string) | string;
  wrapperClassName?: string;
  labelClassName?: string;
  autoFocus?: boolean;

  showErrorAsToolTipIconLeft?: boolean;
  showErrorAsToolTipIconRight?: boolean;

  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
} & InputStyleProps;

// this component is intended to be used to the Form component,
//  as it utilises react hook form's register props

// we should also probably add resuable styles for inputs via cva here

const Input = <TRegister extends string>({
  id,
  type = 'text',
  ariaLabel,
  placeholder,
  disabled = false,
  autoComplete = 'off',
  IconLeft,
  IconRight,
  IconRightClassNames,
  register,
  error,
  className,
  wrapperClassName,
  labelClassName,
  label,
  autoFocus = false,
  variant = 'default',
  showErrorAsToolTipIconLeft = false,
  showErrorAsToolTipIconRight = false,
  value,
  onChange,
  ...props
}: InputProps<TRegister>) => {
  const valueToSet = value === undefined ? {} : { value };

  return (
    <div id="Input" className={`flex flex-col ${wrapperClassName ?? ''}`}>
      {label && (
        <label
          htmlFor={id == undefined ? undefined : `${id}-input`}
          className={`mb-1 text-sm font-semibold ${labelClassName ?? ''}`}
        >
          {label}
        </label>
      )}

      <div className="relative flex items-center">
        {/* {IconLeft && (
                    <IconLeft
                        className={`pointer-events-none absolute left-0 ${
                            IconLeftClassNames
                                ? IconLeftClassNames
                                : 'ml-3 h-5 w-5'
                        }`}
                    />
                )} */}
        {showErrorAsToolTipIconLeft && error && (
          <ToolTip content={error}>
            <IconInfoCircleFilled className="absolute left-0 ml-1 h-4 w-4 text-red-600" />
          </ToolTip>
        )}
        {IconLeft}
        <input
          id={id == undefined ? undefined : `${id}-input`}
          type={type}
          aria-label={ariaLabel}
          aria-invalid={error === undefined || error === null ? false : true}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={twMerge(
            InputStyles({
              variant: variant,
              className: `${type === 'number' ? 'text-right' : ''} ${
                typeof className === 'function'
                  ? className({
                      isError: error ? true : false,
                    })
                  : className
              }`,
            })
          )}
          onChange={onChange}
          {...valueToSet}
          {...register}
          {...props}
        />
        {IconRight && (
          <IconRight
            className={cx(
              'pointer-events-none absolute right-0',
              IconRightClassNames && 'mr-3 h-5 w-5'
            )}
          />
        )}
        {showErrorAsToolTipIconRight && error && (
          <ToolTip content={error}>
            <IconInfoCircleFilled className="absolute left-0 mr-1 h-4 w-4 text-red-600" />
          </ToolTip>
        )}
      </div>

      {error !== null && !showErrorAsToolTipIconLeft && !showErrorAsToolTipIconRight && (
        <p className={`mt-1 text-sm text-rose-600 ${error === undefined ? 'invisible' : ''}`}>
          {error ?? 'no errors'}
        </p>
      )}
    </div>
  );
};

// // same class name for an input
// className={`w-full rounded-md bg-slate-300 py-2 px-11 font-semibold shadow-sm ring-2
//                     ring-slate-300 placeholder:text-slate-400 focus:ring-blue-600
//                     dark:bg-slate-500 dark:ring-slate-500
//                     dark:placeholder:text-slate-400 dark:focus:ring-blue-500`}

// // how to pass props conditionally, without declaring Input twice
// {...{
//     IconRight:
//         email === "" ? undefined : ExclamationTriangleIcon,
//     IconRightClassNames:
//         email === ""
//             ? undefined
//             : "mr-3 h-5 w-5 text-red-500 dark:text-red-300",
// }}

export default Input;
