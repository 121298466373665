import { createContext, forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Checkbox as MantineCheckbox,
  CheckboxGroupProps as MantineCheckboxGroupProps,
  CheckboxGroupStylesNames,
} from '@mantine/core';
import { tv, type VariantProps } from 'tailwind-variants';
import { ClassArrayOrString } from '../../locals/types';

type CheckboxGroupClassNamesType = Partial<Record<CheckboxGroupStylesNames, string>>;

const styles = tv({
  slots: {
    root: '[&>div[role=group]]:flex [&>div[role=group]]:gap-2.5',
    label: 'mb-2.5 text-sm font-normal',
    error: 'mt-2 text-sm text-andisor-red',
    required: 'text-sm text-andisor-red',
  } as ClassArrayOrString<CheckboxGroupClassNamesType>,
  variants: {
    variant: {
      horizontal: {
        root: '[&>div[role=group]]:flex-row [&>div[role=group]]:flex-wrap',
      },
      vertical: {
        root: '[&>div[role=group]]:flex-col',
      },
    },
  },
  defaultVariants: {
    variant: 'horizontal',
  },
});

export interface CheckboxGroupProps
  extends Omit<MantineCheckboxGroupProps, 'variant'>,
    VariantProps<typeof styles> {
  classNames?: CheckboxGroupClassNamesType;
  disabled?: boolean;
  children: React.ReactNode;
}

export const CheckboxGroupContext = createContext({ disabled: false });

export const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
  ({ classNames, variant = 'horizontal', disabled, children, ...restProps }, ref) => {
    const { root, label, error, required } = styles({ variant });

    return (
      <CheckboxGroupContext.Provider value={{ disabled }}>
        <MantineCheckbox.Group
          ref={ref}
          classNames={{
            ...classNames,
            root: root({ class: classNames?.root }),
            label: label({ class: classNames?.label }),
            error: error({ class: classNames?.error }),
            required: required({ class: classNames?.required }),
          }}
          {...restProps}
        >
          {children}
        </MantineCheckbox.Group>
      </CheckboxGroupContext.Provider>
    );
  }
);
