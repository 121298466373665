// https://mantine.dev/core/pill/

import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Pill as MantinePill, PillProps as MantinePillProps, PillStylesNames } from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type PillClassNamesType = Partial<Record<PillStylesNames, string>>;

const styles: ClassArrayOrString<PillClassNamesType> = {};

export interface PillProps extends MantinePillProps {
  classNames?: PillClassNamesType;
}

export const Pill = Object.assign(
  forwardRef<HTMLDivElement, PillProps>(({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return <MantinePill ref={ref} classNames={mergedStyles} {...restProps} />;
  }),
  {
    Group: MantinePill.Group,
  }
);

export type { PillGroupProps } from '@mantine/core';
