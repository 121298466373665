// https://mantine.dev/core/Skeleton/

// eslint-disable-next-line no-restricted-imports
import { Skeleton as MantineSkeleton, SkeletonProps as MantineSkeletonProps } from '@mantine/core';
import { cx } from '../../utils';

export interface SkeletonProps extends MantineSkeletonProps {
  className?: string;
}

export const Skeleton = ({ className, ...restProps }: MantineSkeletonProps) => {
  return (
    <MantineSkeleton className={cx('h-full w-full rounded', className)} visible {...restProps} />
  );
};
