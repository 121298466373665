import { PostHogConfig } from 'posthog-js';

export const POSTHOG_CONFIG: Partial<PostHogConfig> = {
  api_host: 'https://app.posthog.com',
  autocapture: {
    dom_event_allowlist: ['click'],
    element_allowlist: ['a', 'button'],
  },
  rageclick: true, // capture rage clicks, useful for session recording
  capture_pageleave: false, // disable automatic pageleave capture, since its not useful for us
  capture_pageview: false, // disable automatic pageview capture, as we capture manually
  advanced_disable_feature_flags: false, // disable feature flags, since its unused for now
  enable_recording_console_log: true,
};
