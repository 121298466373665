import { forwardRef, type ReactNode } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

const styles = tv({
  base: 'font-heading',
  variants: {
    size: {
      d1: 'text-9xl', // display-1 (used for large headings eg.landing page) /* 100, 106px */
      d2: 'text-8xl', // display-2 (used for large headings eg.landing page) /* 80, 86px */
      h1: 'text-7xl', // heading-1 /* 60, 66px */
      h2: 'text-6xl', // heading-2 /* 48px, 56px */
      h3: 'text-5xl', // heading-3 /* 40px, 48px */
      h4: 'text-4xl', // heading-4 /* 36px, 44px */
      h5: 'text-3xl', // heading-5 /* 30px, 36px */
      h6: 'text-2xl', // heading-6 /* 24px, 30px */
    },
  },
  defaultVariants: {
    size: 'h5',
  },
});

const ElementTypes = {
  d1: 'h1',
  d2: 'h2',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

export interface HeadingProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof styles> {
  children: ReactNode;
  className?: string;
}

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ size = 'h5', children, className, ...restProps }, ref) => {
    const Element = ElementTypes[size] as React.ElementType;

    if (!Element) throw new Error(`Heading size ${size} is not supported`);

    return (
      <Element className={styles({ size, className })} {...restProps} ref={ref}>
        {children}
      </Element>
    );
  }
);
