// https://mantine.dev/core/stepper/
// TODO: implement vertical stepper

import Image from 'next/image';
import {
  // eslint-disable-next-line no-restricted-imports
  Stepper as MantineStepper,
  StepperProps as MantineStepperProps,
  StepperStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from 'src/ui/locals/types';
import { tv, VariantProps } from 'tailwind-variants';

type StepperClassNamesType = Partial<Record<StepperStylesNames, string>>;

const styles = tv({
  slots: {
    separator: 'm-0 ml-3 w-[50px] max-w-[50px] mobile:w-[25px] mobile:max-w-[25px]',
    stepBody: 'mobile:w-[50px] mobile:max-w-[50px]',
    stepLabel:
      'text-white tablet:text-[18px] tablet:leading-6 mobile:text-[14px] mobile:leading-[19px]',
    steps: 'justify-center',
    root: 'flex flex-1 flex-col',
    content: 'w-full',
    stepIcon: '',
  } as ClassArrayOrString<StepperClassNamesType>,
  variants: {
    variant: {
      default: {},
      textDark: {
        stepLabel: 'text-andisor-navy',
      },
      whiteStepIcon: {
        stepLabel: 'text-andisor-navy',
        stepIcon: 'bg-white data-[completed=true]:bg-andisor-blue',
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface StepperProps
  extends Omit<MantineStepperProps, 'variant'>,
    VariantProps<typeof styles> {
  classNames?: StepperClassNamesType;
  className?: string;
}

export const Stepper = ({ classNames, variant = 'default', ...restProps }: StepperProps) => {
  const { separator, stepBody, stepLabel, steps, root, content, stepIcon } = styles({
    variant,
  });
  return (
    <MantineStepper
      classNames={{
        separator: separator?.({ class: classNames?.separator }),
        stepBody: stepBody?.({ class: classNames?.stepBody }),
        stepLabel: stepLabel?.({ class: classNames?.stepLabel }),
        steps: steps?.({ class: classNames?.steps }),
        root: root?.({ class: classNames?.root }),
        content: content?.({ class: classNames?.content }),
        stepIcon: stepIcon?.({ class: classNames?.stepIcon }),
      }}
      icon={
        <Image
          src="/assets/images/onboarding/customer-type/next_step_icon.svg"
          height={24}
          width={24}
          alt="next"
        />
      }
      iconSize={24}
      {...restProps}
    />
  );
};

Stepper.Step = MantineStepper.Step;
Stepper.Completed = MantineStepper.Completed;
