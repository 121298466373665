import axios, { AxiosError } from 'axios';
import { clearUserState } from '@hooks/useAuth';
import { ROUTES } from '@constants/route';

// Ideally, we should access the token on the server side instead of keeping it in browser memory. We should place all our backend APIs on Next.js API routes. These API routes will act as proxies for the backend APIs, but implementing this would require a significant amount of work since all our apis would have to be moved to API routes.
let accessToken = '';
const setAccessToken = (_accessToken: string) => {
  accessToken = _accessToken;
};
const getAccessToken = () => accessToken;
export const clearAccessToken = () => {
  setAccessToken('');
};

let hasTokenApiFailed = false;
const setHasTokenApiFailed = (_bool: boolean) => {
  hasTokenApiFailed = _bool;
};
const getHasTokenApiFailed = () => hasTokenApiFailed;
export const clearHasTokenApiFailed = () => {
  setHasTokenApiFailed(false);
};

export const AXIOS_BACKEND_API_CONFIG = {
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const AXIOS_NEXT_API_CONFIG = {
  baseURL: process.env.NEXT_PUBLIC_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const axiosAuth = axios.create(AXIOS_BACKEND_API_CONFIG);
export const axiosNextApi = axios.create(AXIOS_NEXT_API_CONFIG);

axiosAuth.interceptors.request.use(async config => {
  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    // non logged in user, so subsequent calls don't need to hit the get-token endpoint
    // we use this boolean to avoid repeatedly calling /get-token endpoint. If the endpoint has failed once with a 401, we set this to true and return immediately
    if (getHasTokenApiFailed()) return config;
    try {
      const resp: any = await axiosNextApi.get(ROUTES.NEXT_API.AUTH.GET_TOKEN);
      if (resp?.data?.accessToken) {
        setHasTokenApiFailed(false);
        setAccessToken(resp?.data?.accessToken);
        config.headers.Authorization = `Bearer ${resp.data.accessToken}`;
      }
    } catch (err: any) {
      if (err.response?.status === 401) {
        setHasTokenApiFailed(true);
      }
    }
  }
  return config;
});

axiosAuth.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.href = ROUTES.NEXT_API.AUTH.LOGOUT;
      clearUserState();
    }
    return Promise.reject(error);
  }
);
