// https://mantine.dev/core/pagination/

import { forwardRef } from 'react';
import {
  Pagination as MantinePagination,
  PaginationProps as MantinePaginationProps,
  PaginationStylesNames as __PaginationStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type PaginationClassNamesType = Partial<Record<__PaginationStylesNames, string>>;

const styles: ClassArrayOrString<PaginationClassNamesType> = {
  control: 'text-andisor-navy bg-white',
};

export interface PaginationtProps extends MantinePaginationProps {
  classNames?: PaginationClassNamesType;
}

export const Pagination = Object.assign(
  forwardRef<HTMLInputElement, PaginationtProps>(({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return <MantinePagination ref={ref} classNames={mergedStyles} {...restProps} />;
  })
);
