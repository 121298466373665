// https://mantine.dev/core/select/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
  SelectStylesNames,
} from '@mantine/core';
import { IconChevronDown } from '@ui/icons';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type SelectClassNamesType = Partial<Record<SelectStylesNames, string>>;

const styles: ClassArrayOrString<SelectClassNamesType> = {
  input: 'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100',
  section: 'pointer-events-none',
  label: 'font-semibold leading-[19px] text-andisor-navy-400',
};

export interface SelectProps extends MantineSelectProps {
  classNames?: SelectClassNamesType;
  comboboxProps?: MantineSelectProps['comboboxProps'];
}

const defaultComboboxProps: SelectProps['comboboxProps'] = {
  withinPortal: false,
};

export const SelectRightSection = <IconChevronDown width={20} height={20} />;

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  ({ classNames, comboboxProps: comboxPropsOverride, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    let comboboxProps: SelectProps['comboboxProps'] = defaultComboboxProps;
    if (comboxPropsOverride) {
      comboboxProps = {
        ...defaultComboboxProps,
        ...comboxPropsOverride,
      };
    }

    return (
      <MantineSelect
        ref={ref}
        classNames={mergedStyles}
        comboboxProps={comboboxProps}
        allowDeselect={false}
        maxDropdownHeight={150}
        checkIconPosition="right"
        nothingFoundMessage="No results found"
        rightSection={SelectRightSection}
        {...restProps}
      />
    );
  }
);
