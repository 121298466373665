const ArrowRightSmall = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2308 9.05758H0V8.14665H19.2308V9.05758Z"
        fill="currentColor"
      />
      <path
        d="M19.6802 7.90789V9.28441L12.9109 12.4423V11.1468L18.5304 8.62045L12.9109 6.09413V4.75L19.6802 7.90789Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRightSmall;
