import { useMemo } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useAuth } from '@hooks/useAuth';
import { ROLES } from '@constants';

const PostHogUserIdentifier = () => {
  const { isAuthenticated, user } = useAuth();
  const posthog = usePostHog();

  const posthogIdentifyParams = useMemo(() => {
    if (isAuthenticated) {
      return {
        id: user?.userId,
        auth0Id: user?.auth0Id,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        contactNumber: user?.contactNumber,
        role: user?.role,
        associations:
          user?.role === ROLES.BRAND
            ? user?.brandList?.map(b => ({ id: b.id, name: b.name }))
            : user?.role === ROLES.RETAILER
              ? user?.retailerList?.map(r => ({ id: r.id, name: r.name }))
              : null,
        activeAssociation:
          user?.role === ROLES.BRAND
            ? { id: user?.brandList?.[0].id, name: user?.brandList?.[0].name }
            : user?.role === ROLES.RETAILER
              ? { id: user?.retailerList?.[0].id, name: user?.retailerList?.[0].name }
              : null,
        company:
          user?.role === ROLES.BRAND
            ? user?.parentBrand
            : user?.role === ROLES.RETAILER
              ? user?.parentRetailer
              : null,
      };
    }
    return undefined;
  }, [isAuthenticated, user]);

  // we only fire posthog.identify() when the user is authenticated and only once, so deep compare is needed to avoid refiring on reference changes
  useDeepCompareEffectNoCheck(() => {
    if (posthogIdentifyParams !== undefined && posthog?.__loaded) {
      try {
        posthog?.identify(posthogIdentifyParams.email, posthogIdentifyParams);
        if (posthogIdentifyParams.role !== ROLES.ADMIN && posthogIdentifyParams.company) {
          posthog?.group(
            'company',
            `${posthogIdentifyParams.company.id}`,
            posthogIdentifyParams.company
          );
        }
      } catch {
        console.log('## Failed to fire PostHog Identify event');
      }
    }
  }, [posthogIdentifyParams, posthog?.__loaded, posthog?.identify, posthog?.group]);

  return <></>;
};

export default PostHogUserIdentifier;
