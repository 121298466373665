// https://mantine.dev/others/carousel/

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Carousel as MantineCarousel,
  CarouselProps as MantineCarouselProps,
  type CarouselStylesNames,
  type Embla as MantineEmblaType,
} from '@mantine/carousel';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@ui/icons';
import { ICON_SIZE, ICON_STROKE } from '@ui/constants';
import { ClassArrayOrString } from '../../../locals/types';
import { mergeMantineStyles } from '../../../locals/utils';

type CarouselClassNamesType = Partial<Record<CarouselStylesNames, string>>;

export type Embla = MantineEmblaType;

const styles: ClassArrayOrString<CarouselClassNamesType> = {
  control: [
    'rounded-full text-white bg-andisor-navy border-none',
    'data-[inactive=true]:opacity-0 data-[inactive=true]:cursor-default',
  ],
  indicators: 'gap-2',
  indicator: [
    'h-3 w-3 bg-white rounded-full opacity-40',
    'data-[active=true]:opacity-100 data-[active=true]:border-2 data-[active=true]:border-andisor-gray-500 data-[active=true]:border-solid',
  ],
};

export interface CarouselProps extends MantineCarouselProps {
  classNames?: CarouselClassNamesType;
  children: React.ReactNode;
  height: string | number;
}

export const Carousel = forwardRef(
  ({ classNames, height, children, ...restProps }: CarouselProps, ref) => {
    const [emblaApi, setEmblaApi] = useState<Embla | null>(null);

    useImperativeHandle(
      ref,
      () => {
        return emblaApi;
      },
      [emblaApi]
    );

    const mergedStyles = mergeMantineStyles(styles, classNames);
    const childArray = React.Children.toArray(children);

    return (
      <MantineCarousel
        align="start"
        slideGap="md"
        containScroll="trimSnaps"
        classNames={mergedStyles}
        height={height}
        getEmblaApi={setEmblaApi}
        previousControlIcon={<IconArrowNarrowLeft size={ICON_SIZE.md} stroke={ICON_STROKE.md} />}
        nextControlIcon={<IconArrowNarrowRight size={ICON_SIZE.md} stroke={ICON_STROKE.md} />}
        controlSize={40}
        draggable
        dragFree
        {...restProps}
      >
        {childArray.map((child, idx) => (
          <MantineCarousel.Slide key={idx}>{child}</MantineCarousel.Slide>
        ))}
      </MantineCarousel>
    );
  }
);

export default Carousel;
