// https://mantine.dev/core/drawer/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Drawer as MantineDrawer,
  DrawerProps as MantineDrawerProps,
  DrawerStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type DrawerClassNamesType = Partial<Record<DrawerStylesNames, string>>;

const styles: ClassArrayOrString<DrawerClassNamesType> = {};

export interface DrawerProps extends MantineDrawerProps {
  classNames?: DrawerClassNamesType;
}

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  ({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return <MantineDrawer ref={ref} classNames={mergedStyles} {...restProps} />;
  }
);
