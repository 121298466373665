import { PURGE } from 'redux-persist';
import { z } from 'zod';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { getBrandPageContentSchema } from '@api/buyers/cms';
import { NavigationTreeType } from '@api/common/browse';
import { searchVariantsSchema } from '@api/sellers/inventory';

export interface BrowseState {
  breadcrumb: NavigationTreeType[];
  facets: z.infer<typeof searchVariantsSchema>['facets'];
  selectedFilters: {
    productCategories: string[];
    productTypes: string[];
    targetAudiences: string[];
    userCategories: string[];
    colours: string[];
    scents: string[];
  };
  filterChangeType: string;
  selectedIds: string[];
}

export interface NavigationTreeData {
  [key: string]: NavigationTree;
}

export interface NavigationTree {
  brandId: number;
  data: NavigationTreeType[];
  brandSlug: string;
  brandInfo: z.infer<typeof getBrandPageContentSchema>;
}

const initialState: BrowseState = {
  breadcrumb: [],
  facets: {
    targetAudiences: [],
    userCategories: [],
    productCategories: [],
    productTypes: [],
    colours: [],
    scents: [],
  },
  filterChangeType: '',
  selectedFilters: {
    productCategories: [],
    productTypes: [],
    targetAudiences: [],
    userCategories: [],
    colours: [],
    scents: [],
  },
  selectedIds: [],
};

const browseSlice = createSlice({
  name: 'browse',
  initialState,
  reducers: {
    updateBreadcrumb: (state, action: PayloadAction<NavigationTreeType[]>) => {
      state.breadcrumb = action.payload;
    },
    updateFacets: (
      state,
      action: PayloadAction<z.infer<typeof searchVariantsSchema>['facets']>
    ) => {
      if (state.filterChangeType === '') {
        state.facets = action.payload;
        state.selectedIds = [];
        return;
      }
      if (
        state.filterChangeType === 'productCategories' ||
        state.filterChangeType === 'targetAudiences' ||
        state.filterChangeType === 'userCategories'
      ) {
        state.facets.productTypes = action.payload.productTypes;
        state.facets.colours = action.payload.colours;
        state.facets.scents = action.payload.scents;
      }
    },
    updateSelectedFilters: (state, action: PayloadAction<BrowseState['selectedFilters']>) => {
      state.selectedFilters = action.payload;
    },
    updateSelectedIds: (state, action: PayloadAction<BrowseState['selectedIds']>) => {
      state.selectedIds = action.payload;
    },
    updateFilterChangeType: (state, action: PayloadAction<string>) => {
      state.filterChangeType = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  updateBreadcrumb,
  updateFacets,
  updateFilterChangeType,
  updateSelectedFilters,
  updateSelectedIds,
} = browseSlice.actions;

export default browseSlice;
