// https://mantine.dev/core/pills-input/

import { forwardRef } from 'react';
import {
  __InputStylesNames,
  // eslint-disable-next-line no-restricted-imports
  PillsInput as MantinePillsInput,
  PillsInputProps as MantinePillsInputProps,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type PillsInputClassNamesType = Partial<Record<__InputStylesNames, string>>;

const styles: ClassArrayOrString<PillsInputClassNamesType> = {
  label: 'text-sm font-semibold',
  input: 'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100',
};

export interface PillsInputProps extends MantinePillsInputProps {
  classNames?: PillsInputClassNamesType;
}

export const PillsInput = Object.assign(
  forwardRef<HTMLInputElement, PillsInputProps>(({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return <MantinePillsInput ref={ref} classNames={mergedStyles} {...restProps} />;
  }),
  {
    Field: MantinePillsInput.Field,
  }
);

export type { PillsInputFieldProps } from '@mantine/core';
