import { PURGE } from 'redux-persist';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface SelectedFilters {
  productCategories: string[];
  userCategories: string[];
  targetAudience: string[];
  topography: string[];
  spot: string[];
  size: string[];
  values: string[];
  priceSegment: string;
}

export interface FilterPayload {
  personaId: number;
  filters: SelectedFilters;
}

export interface PersonaState {
  filtersByPersona: Record<number, SelectedFilters>;
}

const initialState: PersonaState = {
  filtersByPersona: {},
};

const personaSlice = createSlice({
  name: 'persona',
  initialState,
  reducers: {
    saveFiltersByPersona: (state, action: PayloadAction<FilterPayload>) => {
      const { personaId, filters } = action.payload;
      if (!state.filtersByPersona[personaId]) {
        state.filtersByPersona[personaId] = {} as SelectedFilters;
      }
      state.filtersByPersona[personaId] = filters;
    },
    deleteFiltersForPersona: (state, action: PayloadAction<{ personaId: number | undefined }>) => {
      const { personaId } = action.payload;
      if (personaId && state.filtersByPersona[personaId]) delete state.filtersByPersona[personaId];
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { saveFiltersByPersona, deleteFiltersForPersona } = personaSlice.actions;

export default personaSlice;
