const ArrowLeftSmall = ({ className }: { className?: string }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.44887 10.3877L20.6797 10.3877L20.6797 11.2987L1.44887 11.2987L1.44887 10.3877Z"
            fill="currentColor"
          />
          <path
            d="M0.999527 11.5374L0.999527 10.1609L7.76878 7.003L7.76878 8.29855L2.14933 10.8249L7.76878 13.3512L7.76878 14.6953L0.999527 11.5374Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeftSmall;
