// https://mantine.dev/core/menu/

import { forwardRef } from 'react';
import {
  createPolymorphicComponent,
  // eslint-disable-next-line no-restricted-imports
  Menu as MantineMenu,
  MenuItemProps as MantineMenuItemProps,
  MenuProps as MantineMenuProps,
  MenuStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';
import { Loader } from '../Loader';

type MenuClassNamesType = Partial<Record<MenuStylesNames, string>>;

const styles: ClassArrayOrString<MenuClassNamesType> = {
  item: ['text-sm transition-colors', 'hover:bg-andisor-gray/70'],
};

export interface MenuProps extends MantineMenuProps {
  children: React.ReactNode;
  classNames?: MenuClassNamesType;
}

export const Menu = ({ children, classNames, ...restProps }: MenuProps) => {
  const mergedStyles = mergeMantineStyles(styles, classNames);

  return (
    <MantineMenu
      classNames={mergedStyles}
      shadow="md"
      withinPortal={false}
      closeOnItemClick
      {...restProps}
    >
      {children}
    </MantineMenu>
  );
};

export interface MenuItemProps extends MantineMenuItemProps {
  disabled?: boolean;
  loading?: boolean;
  leftSection?: React.ReactNode;
  children: React.ReactNode;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const MenuItem = createPolymorphicComponent<'button', MenuItemProps>(
  forwardRef<HTMLButtonElement, MenuItemProps>(
    ({ disabled = false, loading = false, children, leftSection, onClick, ...restProps }, ref) => {
      return (
        <MantineMenu.Item
          ref={ref}
          disabled={disabled || loading}
          leftSection={loading ? <Loader size="xs" /> : leftSection}
          onClick={onClick}
          {...restProps}
        >
          {children}
        </MantineMenu.Item>
      );
    }
  )
);

Menu.Target = MantineMenu.Target;
Menu.Dropdown = MantineMenu.Dropdown;
Menu.Item = MenuItem;
Menu.Divider = MantineMenu.Divider;
export type { MenuDividerProps, MenuDropdownProps, MenuTargetProps } from '@mantine/core';
