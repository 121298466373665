// eslint-disable-next-line simple-import-sort/imports
import React, { Fragment, ReactElement, ReactNode, Suspense, useCallback } from 'react';
import { NextPage } from 'next';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import prismatic from '@prismatic-io/embedded';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import NextAdapterPages from 'next-query-params/pages';
import NProgress from 'nprogress';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { parse as parseStackTrace } from 'stacktrace-parser';
import { QueryParamProvider } from 'use-query-params';
import { modalProps } from '@ui/core';
import { PlatformCheckPopup } from '@components/PlatformCheckPopup/PlatformCheckPopup';
import PostHogUserIdentifier from '@components/posthog/PostHogUserIdentifier';
import { ResponsiveScreenHelper } from '@components/ResponsiveScreenHelper';
import { POSTHOG_CONFIG } from '@libs/posthog.config';
import { persistor, store } from '@redux/store';
import { useAuth } from '@hooks/useAuth';
import { aileronFont, tiemposFont } from '@styles/fonts';
import { mantineTheme } from '@styles/mantineTheme';
// DO NOT CHANGE THE ORDER OF STYLE IMPORTS HERE!
/* Mantine seems to include some global styles by default and we want to override them with Tailwind's config */
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import '@styles/base.css';
import '@styles/ag-theme-compact.css';
import '@styles/supplier-dashboard.css';
import '@mantine/dates/styles.css';
import 'mantine-datatable/styles.layer.css';
import '@styles/master-data-view.css';
// ErrorFallback uses css module which needs to render last for css specificity
import ErrorFallback from '@components/ErrorFallback/ErrorFallback';
import 'simplebar-react/dist/simplebar.min.css';
// initialize prismatic
if (typeof window !== 'undefined') {
  prismatic.init({
    prismaticUrl: process.env.NEXT_PUBLIC_PRISMATIC_URL,
    screenConfiguration: {
      initializing: {
        background: '#FFFFFF',
        color: '#357FEF',
      },
      instance: {
        hideBackToMarketplace: true,
        hideTabs: ['Monitors', 'Test', 'Executions', 'Logs'],
      },
      configurationWizard: {
        hideSidebar: true,
        isInModal: true,
        triggerDetailsConfiguration: 'hidden',
      },
      marketplace: {
        configuration: 'disallow-details',
      },
    },
    fontConfiguration: {
      google: {
        families: ['Inter'],
      },
    },
  });
}

// initialize ag-grid
LicenseManager.setLicenseKey('');

// posthog analytics setup, check that PostHog is client-side
export let posthogInstance = undefined;
if (
  process.env.NEXT_PUBLIC_ENV !== 'dev' &&
  process.env.NODE_ENV === 'production' &&
  typeof window !== 'undefined'
) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_PUBLIC_KEY as string, {
    ...POSTHOG_CONFIG,
    loaded: ph => {
      posthogInstance = ph;
    },
  });
}

// NProgress config
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => {
  // capture pageview event
  posthog?.capture('$pageview');
  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

// react-hot-toast container styles
const toastStyles = {
  zIndex: 10005,
  bottom: 110,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // any subsequent quries with the same queryKey that occurs between
      // 0 and 20 seconds (below) will be retrieved from cache
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
    },
  },
});

const queryParamProviderOptions = {
  removeDefaultsFromUrl: true,
  updateType: 'replaceIn',
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const metricsToCapture = ['FCP', 'LCP', 'FID', 'CLS'];
  if (metricsToCapture.includes(metric?.name)) {
    posthog?.capture(metric.name, metric);
  }
}

type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  layout?: (page: ReactElement) => ReactNode;
  getLayout?: (page: ReactElement, pageProps: P) => ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
}

const WrappedComponent = ({ children }: { children: React.ReactNode }) => {
  const { isLoading } = useAuth();

  // render nothing while auth0 sdk is still loading
  // this is ensure the entire user object is avaliable to use and avoid double api call issues
  if (isLoading) return <></>;

  return <>{children}</>;
};

const App = ({ Component, pageProps }: MyAppProps) => {
  const getLayout = Component.layout || ((page, _) => page);

  const onAppError = useCallback((error: Error, info: { componentStack: string }) => {
    const trace = parseStackTrace(info?.componentStack ?? '');
    posthog?.capture('$exception', {
      name: error?.name ?? '',
      message: error?.message ?? '',
      fileName: trace[0]?.file ?? '',
      lineNumber: trace[0]?.lineNumber ?? '',
      stackTrace: info?.componentStack ?? '',
    });
  }, []);

  return (
    <Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Andisor - Buying done better</title>
        <meta name="title" content="Andisor - Buying done better" />
        <meta name="description" content="Buying done better" />
        <meta property="og:url" content={process.env.NEXT_PUBLIC_APP_BASE_URL} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Andisor - Buying done better" />
        <meta property="og:description" content="Buying done better" />
        <style
          dangerouslySetInnerHTML={{
            // https://github.com/vercel/next.js/issues/44840
            __html: `
              :root {
                --font-tiempos-sans: ${tiemposFont.style.fontFamily};
                --font-aileron-sans: ${aileronFont.style.fontFamily};
              }`,
          }}
        />
      </Head>
      <Suspense fallback={<></>}>
        <PostHogProvider client={posthog}>
          <UserProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools />
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <MantineProvider
                    theme={mantineTheme}
                    defaultColorScheme="light"
                    withCssVariables={false}
                  >
                    <ModalsProvider modalProps={modalProps}>
                      <QueryParamProvider
                        adapter={NextAdapterPages}
                        options={queryParamProviderOptions}
                      >
                        <ErrorBoundary FallbackComponent={ErrorFallback} onError={onAppError}>
                          <PlatformCheckPopup />
                          <PostHogUserIdentifier />
                          <ResponsiveScreenHelper show={process.env.NODE_ENV === 'development'} />
                          {getLayout(
                            <WrappedComponent>
                              <Component {...pageProps} />
                            </WrappedComponent>,
                            pageProps
                          )}
                        </ErrorBoundary>
                      </QueryParamProvider>
                    </ModalsProvider>
                  </MantineProvider>
                </PersistGate>
              </Provider>
            </QueryClientProvider>
          </UserProvider>
        </PostHogProvider>
      </Suspense>
      <div>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          gutter={8}
          containerStyle={toastStyles}
        />
      </div>
    </Fragment>
  );
};

export default App;
