import { useCallback } from 'react';
import { UserContext, useUser } from '@auth0/nextjs-auth0/client';
import { posthogInstance } from '@pages/_app';
import { axiosNextApi, clearAccessToken, clearHasTokenApiFailed } from '@libs/axios';
import { clearPersistedState } from '@redux/store';
import { ROUTES } from '@constants/route';

interface User {
  userId?: number | null;
  firstName?: string;
  lastName?: string;
  profilePicture?: string;
  contactNumber?: string;
  email?: string;
  role?: string;
  auth0Id?: string | undefined;
  brandList?:
    | {
        id: number;
        name: string;
        logoURL: string | null;
        hasIntegrationEnabled: boolean;
        slug: string;
      }[]
    | null;
  activeBrand?: {
    id: number;
    name: string;
    logoURL: string | null;
    hasIntegrationEnabled: boolean;
    slug: string;
  } | null;
  parentBrand?: {
    id: number;
    name: string;
  } | null;
  retailerList?:
    | {
        id: number;
        name: string;
        logoURL: string | null;
      }[]
    | null;
  activeRetailer?: {
    id: number;
    name: string;
    logoURL: string | null;
  } | null;
  parentRetailer?: {
    id: number;
    name: string;
    isDropshipEligible: boolean;
    hasEnabledMarketplace: boolean;
  } | null;
}

type UserType = User | undefined;

export const clearUserState = async () => {
  clearAccessToken();
  clearHasTokenApiFailed();
  posthogInstance?.reset();
  await clearPersistedState();
};

export const useAuth = () => {
  const { user, error, isLoading, checkSession }: UserContext = useUser();

  const updateSession = useCallback(
    async (payload: User) => {
      // calls the next api route to update the session cookie
      await axiosNextApi.put(ROUTES.NEXT_API.AUTH.UPDATE_USER, payload);
      // ensures the `useUser()` client hook gets the new user data
      await checkSession();
    },
    [checkSession]
  );

  return {
    isAuthenticated: !!user,
    user: user as UserType,
    error,
    isLoading,
    updateSession,
  };
};
