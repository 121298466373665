import { useCallback } from 'react';
import { capitalCase } from 'capital-case';
import { axiosNextApi } from '@libs/axios';
import { useAuth } from '@hooks/useAuth';
import { ROLES } from '@constants';
import { ROUTES } from '@constants/route';

export type TicketPayload = {
  subject: string;
  content: string;
  priority: string;
  email: string;
  firstName: string;
  lastName: string;
  contactType: string;
  companyName: string;
  contactNumber?: string;
  countryOfOrigin?: string;
  website?: string;
  ownerId?: string;
};

export type ContactPayload = {
  email: string;
  firstName: string;
  lastName: string;
  contactType: string;
  companyName: string;
  contactNumber?: string;
  countryOfOrigin?: string;
  website?: string;
};

export enum PriorityList {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum ContactTypes {
  SELLER = 'Seller',
  BUYER = 'Buyer',
  SALES_AGENT = 'Sales Agent',
}

const useHubspot = () => {
  const { user } = useAuth();

  const getTicketPayload = useCallback((data: TicketPayload) => {
    const { subject, content, priority, ownerId, ...details } = data;

    const ticketDetails = {
      ...details,
      contactType:
        data.contactType === ROLES.BRAND
          ? ContactTypes.SELLER
          : data.contactType === ROLES.RETAILER
            ? ContactTypes.BUYER
            : ContactTypes.SALES_AGENT,
      companyName: capitalCase(details.companyName),
    };

    const detailsText = Object.entries(ticketDetails)
      .filter(([_, value]) => Boolean(value))
      .map(([key, value]) => {
        return `\t• ${capitalCase(key)}: ${value}`;
      })
      .join('\n');

    return {
      ...ticketDetails,
      subject: `${subject} - ${data.firstName} ${data.lastName} (${ticketDetails.contactType} - ${ticketDetails.companyName})`,
      content: `${content}

Ticket Details:
${detailsText}
      `,
      priority,
      ownerId,
    };
  }, []);

  const createContact = useCallback(async (payload: ContactPayload) => {
    const createContactPayload = {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      contactNumber: payload.contactNumber,
      contactType: payload.contactType,
      companyName: payload.companyName,
      countryOfOrigin: payload.countryOfOrigin,
      website: payload.website,
    };

    if (process.env.NEXT_PUBLIC_ENV !== 'prod') {
      console.log('## Contact payload: ', createContactPayload);
      return true;
    }

    try {
      const readContactResponse = await axiosNextApi.get(ROUTES.NEXT_API.HUBSPOT.READ_CONTACT, {
        params: { email: payload.email },
      });
      return readContactResponse.data.contactId;
    } catch (err: any) {
      if (err?.response?.status !== 404) throw new Error('Issue reading Hubspot contact');
    }

    try {
      const createContactResponse = await axiosNextApi.post(
        ROUTES.NEXT_API.HUBSPOT.CREATE_CONTACT,
        createContactPayload
      );
      return createContactResponse.data.contactId;
    } catch {
      throw new Error('Issue with Hubspot contact creation');
    }
  }, []);

  const createTicket = useCallback(
    async (payload: TicketPayload) => {
      if (process.env.NEXT_PUBLIC_ENV !== 'prod') {
        console.log('## Ticket payload: ', payload);
        return true;
      }
      /*
      Steps:
      * Check if contact exists in hubspot
      * If not then create a new contact
      * Create the ticket and associate it to the contact
      */
      const contactId = await createContact(payload as ContactPayload);

      await axiosNextApi.post(ROUTES.NEXT_API.HUBSPOT.CREATE_TICKET, {
        contactId,
        subject: payload.subject,
        content: payload.content,
        priority: payload.priority,
        ownerId: payload.ownerId,
      });
    },
    [createContact]
  );

  const raiseIntegrationRequest = useCallback(
    async (integration: string[] | string, isNewIntegration: boolean = false) => {
      const formattedIntegration = Array.isArray(integration)
        ? integration.map(item => `\t• ${item}`).join('\n')
        : integration;

      const companyName =
        user?.role === ROLES.BRAND ? user?.parentBrand?.name : user?.parentRetailer?.name;

      const payload: TicketPayload = getTicketPayload({
        subject: `${isNewIntegration ? 'New Integration' : 'Integration'} Request`,
        content: `${user?.firstName} ${user?.lastName} has requested ${
          isNewIntegration ? 'a New integration:' : 'the following integrations:'
        }
${formattedIntegration}`,
        priority: PriorityList.LOW,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        contactNumber: user?.contactNumber,
        contactType: user?.role,
        companyName,
      });

      await createTicket(payload);
    },
    [createTicket, getTicketPayload, user]
  );

  const raiseHelpRequest = useCallback(
    async data => {
      const payload: TicketPayload = getTicketPayload({
        subject: 'Support Request',
        content: `${data.firstName} ${data.lastName} has raised a support request.

Help required for: ${data.helpOption}

Problem Description:
${data.description}`,
        priority: PriorityList.HIGH,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        contactNumber: data.contactNumber,
        contactType: data.role,
        companyName: data.companyName,
      });

      await createTicket(payload);
    },
    [createTicket, getTicketPayload]
  );

  const raiseCallbackRequest = useCallback(async () => {
    const companyName =
      user?.role === ROLES.BRAND ? user?.parentBrand?.name : user?.parentRetailer?.name;

    const payload: TicketPayload = getTicketPayload({
      subject: 'Callback Request',
      content: `${user?.firstName} ${user?.lastName} has requested a callback.`,
      priority: PriorityList.MEDIUM,
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      contactNumber: user?.contactNumber,
      contactType: user?.role,
      companyName,
    });

    await createTicket(payload);
  }, [createTicket, getTicketPayload, user]);

  return {
    raiseIntegrationRequest,
    raiseHelpRequest,
    raiseCallbackRequest,
    // TODO: need to refactor the createTicket and getTicketPayload
    createTicket,
    createContact,
    getTicketPayload,
  };
};

export default useHubspot;
