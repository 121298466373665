import {
  Combobox,
  createTheme,
  Menu,
  Popover,
  ScrollArea,
  ScrollAreaAutosize,
  Tooltip,
} from '@mantine/core';

export const mantineTheme = createTheme({
  /* Put your mantine theme override here */
  cursorType: 'pointer',
  components: {
    Combobox: Combobox.extend({
      defaultProps: {
        withinPortal: false,
      },
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        withinPortal: false,
      },
    }),
    Popover: Popover.extend({
      defaultProps: {
        withinPortal: false,
      },
    }),
    ScrollArea: ScrollArea.extend({
      defaultProps: {
        type: 'auto',
      },
    }),
    ScrollAreaAutosize: ScrollAreaAutosize.extend({
      defaultProps: {
        type: 'auto',
      },
    }),
    Menu: Menu.extend({
      defaultProps: {
        withinPortal: false,
      },
    }),
  },
});
