import { createContext, forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Radio as MantineRadio,
  RadioGroupProps as MantineRadioGroupProps,
  RadioGroupStylesNames,
} from '@mantine/core';
import { tv, type VariantProps } from 'tailwind-variants';
import { ClassArrayOrString } from '../../locals/types';

type RadioGroupClassNamesType = Partial<Record<RadioGroupStylesNames, string>>;

const styles = tv({
  slots: {
    root: '[&>div[role=radiogroup]]:flex [&>div[role=radiogroup]]:gap-4',
    label: 'mb-2.5 text-sm font-normal',
    error: 'mt-2 text-sm text-andisor-red',
    required: 'text-sm text-andisor-red',
  } as ClassArrayOrString<RadioGroupClassNamesType>,
  variants: {
    variant: {
      horizontal: {
        root: '[&>div[role=radiogroup]]:flex-row',
      },
      vertical: {
        root: '[&>div[role=radiogroup]]:flex-col',
      },
    },
  },
  defaultVariants: {
    variant: 'horizontal',
  },
});

export interface RadioGroupProps
  extends Omit<MantineRadioGroupProps, 'variant'>,
    VariantProps<typeof styles> {
  classNames?: RadioGroupClassNamesType;
  disabled?: boolean;
  children: React.ReactNode;
}

export const RadioGroupContext = createContext({ disabled: false });

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ classNames, variant = 'horizontal', disabled, children, ...restProps }, ref) => {
    const { root, label, error, required } = styles({ variant });

    return (
      <RadioGroupContext.Provider value={{ disabled }}>
        <MantineRadio.Group
          ref={ref}
          classNames={{
            ...classNames,
            root: root({ class: classNames?.root }),
            label: label({ class: classNames?.label }),
            error: error({ class: classNames?.error }),
            required: required({ class: classNames?.required }),
          }}
          {...restProps}
        >
          {children}
        </MantineRadio.Group>
      </RadioGroupContext.Provider>
    );
  }
);
