// https://mantine.dev/core/accordion/

import {
  // eslint-disable-next-line no-restricted-imports
  Accordion as MantineAccordion,
  AccordionProps as MantineAccordionProps,
  AccordionStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type AccordionClassNamesType = Partial<Record<AccordionStylesNames, string>>;

const styles: ClassArrayOrString<AccordionClassNamesType> = {};

export interface AccordionProps extends MantineAccordionProps<true> {
  classNames?: AccordionClassNamesType;
}

export const Accordion = ({ classNames, ...restProps }: AccordionProps) => {
  const mergedStyles = mergeMantineStyles(styles, classNames);

  return <MantineAccordion classNames={mergedStyles} {...restProps} />;
};

Accordion.Item = MantineAccordion.Item;
Accordion.Control = MantineAccordion.Control;
Accordion.Panel = MantineAccordion.Panel;
export type {
  AccordionItemProps,
  AccordionControlProps,
  AccordionPanelProps,
  AccordionValue,
  AccordionHeadingOrder,
} from '@mantine/core';
