interface ResponsiveScreenHelperProps {
  show: boolean;
}

const ResponsiveScreenHelper = ({ show = false }: ResponsiveScreenHelperProps) => {
  if (show === false) {
    return null;
  }

  return (
    <div
      id="ResponsiveScreenHelper"
      className="fixed left-1 top-1 z-responsive-screen-helper hidden h-6 w-6 items-center justify-center
            rounded-sm border border-andisor-navy-300 bg-andisor-navy font-semibold text-andisor-navy-50 laptop:flex"
    >
      <span className="hidden mobile:block">M</span>
      <span className="hidden tablet:block mobile:hidden">T</span>
      <span className="hidden laptop:block tablet:hidden">L</span>
    </div>
  );
};

export { ResponsiveScreenHelper };
