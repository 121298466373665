// https://mantine.dev/core/checkbox/

import { forwardRef, useContext } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Checkbox as MantineCheckbox,
  CheckboxProps as MantineCheckboxProps,
  CheckboxStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';
import { CheckboxGroup, CheckboxGroupContext } from './CheckboxGroup';

export type CheckboxClassNamesType = Partial<Record<CheckboxStylesNames, string>>;

const styles: ClassArrayOrString<CheckboxClassNamesType> = {
  body: 'flex items-center',
  input: [
    'bg-white border-andisor-navy rounded-[0.25rem]',
    'checked:bg-andisor-blue checked:border-transparent',
    'hover:enabled:border-2 hover:enabled:border-andisor-blue',
    'enabled:cursor-pointer',
    'disabled:border-andisor-gray-500 disabled:cursor-not-allowed disabled:checked:bg-andisor-gray-300',
  ],
  icon: 'text-andisor-white',
  label: [
    'text-base font-body select-none cursor-pointer',
    'data-[disabled=true]:text-andisor-gray-500 data-[disabled=true]:cursor-not-allowed',
  ],
};

export interface CheckboxProps extends MantineCheckboxProps {
  classNames?: CheckboxClassNamesType;
  disabled?: boolean;
}

export const Checkbox = Object.assign(
  forwardRef<HTMLInputElement, CheckboxProps>(
    ({ classNames, disabled = false, ...restProps }, ref) => {
      const mergedStyles = mergeMantineStyles(styles, classNames);

      const { disabled: groupDisabled } = useContext(CheckboxGroupContext);

      return (
        <MantineCheckbox
          ref={ref}
          classNames={mergedStyles}
          style={{
            '--checkbox-size': '1.125rem',
          }}
          disabled={groupDisabled || disabled}
          {...restProps}
        />
      );
    }
  ),
  {
    Group: CheckboxGroup,
  }
);
