// https://mantine.dev/core/slider/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Slider as MantineSlider,
  SliderProps as MantineSliderProps,
  SliderStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../../locals/types';
import { mergeMantineStyles } from '../../../locals/utils';

type SliderClassNamesType = Partial<Record<SliderStylesNames, string>>;

const styles: ClassArrayOrString<SliderClassNamesType> = {};

export interface SliderProps extends MantineSliderProps {
  classNames?: SliderClassNamesType;
}

export const Slider = forwardRef<HTMLDivElement, SliderProps>(
  ({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return <MantineSlider ref={ref} classNames={mergedStyles} {...restProps} />;
  }
);
