// https://mantine.dev/core/tooltip/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Tooltip as MantineTooltip,
  TooltipProps as MantineTooltipProps,
  TooltipStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type TooltipClassNamesType = Partial<Record<TooltipStylesNames, string>>;

const styles: ClassArrayOrString<TooltipClassNamesType> = {
  tooltip: 'text-xs px-1.5 py-[3px] bg-andisor-navy z-[1000]',
};

export interface TooltipProps extends MantineTooltipProps {
  classNames?: TooltipClassNamesType;
  visible?: boolean;
}

export const Tooltip = Object.assign(
  forwardRef<HTMLDivElement, TooltipProps>(({ classNames, visible = true, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return (
      <MantineTooltip
        ref={ref}
        classNames={mergedStyles}
        withinPortal={false}
        position="bottom"
        arrowSize={5}
        offset={4}
        withArrow
        disabled={!visible}
        {...restProps}
      />
    );
  }),
  {
    Group: MantineTooltip.Group,
    Floating: MantineTooltip.Floating,
  }
);

export type { TooltipGroupProps, TooltipFloatingProps } from '@mantine/core';
