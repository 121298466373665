// https://mantine.dev/core/textarea/

import { forwardRef } from 'react';
import {
  __InputStylesNames,
  // eslint-disable-next-line no-restricted-imports
  Textarea as MantineTextarea,
  TextareaProps as MantineTextareaProps,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type TextareaClassNamesType = Partial<Record<__InputStylesNames, string>>;

const styles: ClassArrayOrString<TextareaClassNamesType> = {
  input: 'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100',
  label: 'font-semibold leading-[19px] text-andisor-navy-400',
};

export interface TextareaProps extends MantineTextareaProps {
  classNames?: TextareaClassNamesType;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ classNames, ...restProps }: MantineTextareaProps, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return (
      <MantineTextarea
        ref={ref}
        classNames={mergedStyles}
        minRows={2}
        autoComplete="off"
        autosize
        {...restProps}
      />
    );
  }
);
