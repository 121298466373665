import { useEffect } from 'react';
import { modals } from '@mantine/modals';
import { isMobile } from 'react-device-detect';
import { Text } from '@ui/core';
import { IconInfoCircle } from '@ui/icons';

export const PlatformCheckPopup = () => {
  useEffect(() => {
    if (isMobile) {
      if (sessionStorage.getItem('isPlatformCheckPopupDismissed') === 'true') {
        return;
      }

      modals.open({
        children: (
          <div className="flex items-center gap-2">
            <IconInfoCircle className="h-6 w-6 shrink-0 text-andisor-pink" />
            <Text>Please log in from your desktop for a superior experience.</Text>
          </div>
        ),
        classNames: {
          header: 'pt-3 px-3 pb-1',
          body: 'px-4 pb-6',
        },
        trapFocus: false,
        onClose: () => {
          sessionStorage.setItem('isPlatformCheckPopupDismissed', 'true');
          modals.closeAll();
        },
      });
    }
  }, []);

  return <></>;
};
