// https://mantine.dev/others/carousel/

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Carousel as MantineCarousel,
  CarouselProps as MantineCarouselProps,
  type CarouselStylesNames,
  type Embla as MantineEmblaType,
} from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import Classnames from 'embla-carousel-class-names';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@ui/icons';
import { ICON_SIZE, ICON_STROKE } from '@ui/constants';
import { ClassArrayOrString } from '../../../locals/types';
import { mergeMantineStyles } from '../../../locals/utils';
import moduleStyles from './CarouselAutoplay.module.css';

type CarouselClassNamesType = Partial<Record<CarouselStylesNames, string>>;

const styles: ClassArrayOrString<CarouselClassNamesType> = {
  slide: moduleStyles.emblaSlide,
  control: [
    'text-andisor-navy border-none shadow-none',
    'data-[inactive=true]:opacity-0 data-[inactive=true]:cursor-default',
  ],
  indicators: 'gap-2',
  indicator: [
    'h-3 w-3 bg-white rounded-full opacity-40',
    'data-[active=true]:opacity-100 data-[active=true]:border-2 data-[active=true]:border-andisor-gray-500 data-[active=true]:border-solid',
  ],
};

export interface CarouselAutoplayProps extends MantineCarouselProps {
  classNames?: CarouselClassNamesType;
  children: React.ReactNode;
  delay?: number;
  height: string | number;
}

export const CarouselAutoplay = forwardRef(
  ({ classNames, height, delay = 8000, children, ...restProps }: CarouselAutoplayProps, ref) => {
    const [emblaApi, setEmblaApi] = useState<MantineEmblaType | null>(null);
    const autoplayPlugin = useRef(Autoplay({ delay, jump: true, stopOnInteraction: false }));
    const classNamesPlugin = useRef(Classnames());

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const clearTransform = useCallback(() => {
      if (!emblaApi) return;

      emblaApi.internalEngine().translate.toggleActive(false);
      emblaApi.internalEngine().translate.clear();
    }, [emblaApi]);

    const resetAutoplayTimer = () => {
      if (!emblaApi) return;
      emblaApi.plugins()?.autoplay?.reset();
    };

    useEffect(() => {
      if (emblaApi) {
        const onSelect = () => {
          setActiveSlideIndex(emblaApi.selectedScrollSnap());
        };

        onSelect();
        // clear default embla transform to apply the fade effect
        clearTransform();
        emblaApi.on('select', onSelect);

        return () => {
          emblaApi.off('select', onSelect);
        };
      }
      return undefined;
    }, [emblaApi, clearTransform]);

    useImperativeHandle(
      ref,
      () => {
        return emblaApi;
      },
      [emblaApi]
    );

    const mergedStyles = mergeMantineStyles(styles, classNames);

    const childArray = React.Children.toArray(children);

    return (
      <MantineCarousel
        classNames={mergedStyles}
        styles={{
          // TODO: Investigate why this is needed, since we already set in the classNames prop. Mantine's base carousel styles in _app.tsx seem to prevent applying the module styles
          slide: {
            position: 'absolute',
          },
        }}
        height={height}
        draggable={false}
        plugins={[autoplayPlugin.current, classNamesPlugin.current]}
        getEmblaApi={setEmblaApi}
        previousControlIcon={<IconArrowNarrowLeft size={ICON_SIZE.xl} stroke={ICON_STROKE.md} />}
        previousControlProps={{
          onClick: resetAutoplayTimer,
        }}
        nextControlIcon={<IconArrowNarrowRight size={ICON_SIZE.xl} stroke={ICON_STROKE.md} />}
        nextControlProps={{
          onClick: resetAutoplayTimer,
        }}
        {...restProps}
      >
        {childArray.map((child, idx) => (
          <MantineCarousel.Slide key={idx} data-active={idx === activeSlideIndex}>
            {child}
          </MantineCarousel.Slide>
        ))}
      </MantineCarousel>
    );
  }
);

export default CarouselAutoplay;
