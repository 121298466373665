import { cx } from '../../utils';

export function mergeMantineStyles(defaultStyles: any, propStyles: any) {
  if (!propStyles) return defaultStyles;

  const mergedStyles = { ...defaultStyles };

  for (const key in propStyles) {
    mergedStyles[key] = defaultStyles[key]
      ? cx(defaultStyles[key], propStyles[key])
      : propStyles[key];
  }

  return mergedStyles;
}
