import { PURGE } from 'redux-persist';
import { z } from 'zod';
import { SelectedVariantsSchema } from '@layout/sellers/inventory/InventorySubLayout';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface SelectedFilters {
  productCategories: string[];
  userCategories: string[];
  targetAudience: string[];
  topography: string[];
  spot: string[];
  size: string[];
  values: string[];
  priceSegment: string;
}

export type SelectedVariantsType = z.infer<typeof SelectedVariantsSchema>;

export interface InventoryState {
  selectedVariants: SelectedVariantsType;
  selectedVariantIds: string[];
}

const initialState: InventoryState = {
  selectedVariants: {},
  selectedVariantIds: [],
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    updateSelectedVaraints: (state, action: PayloadAction<SelectedVariantsType>) => {
      state.selectedVariants = action.payload;

      // update selected variant ids
      const variantIds: string[] = [];
      Object.keys(action.payload).forEach(key => {
        const product = action.payload[key];
        if (product) {
          product.variants.forEach(variant => variantIds.push(variant.id));
        }
      });
      state.selectedVariantIds = variantIds;
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { updateSelectedVaraints } = inventorySlice.actions;

export default inventorySlice;
