// https://mantine.dev/core/action-icon/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  ActionIcon as MantineActionIcon,
  ActionIconProps as MantineActionIconProps,
  createPolymorphicComponent,
} from '@mantine/core';
import { type VariantProps } from 'tailwind-variants';
import { Tooltip, type TooltipProps } from '../Tooltip';
import { actionIconRootStyles } from './styles';

export interface ActionIconProps
  extends Omit<MantineActionIconProps, 'size' | 'variant' | 'color'>,
    VariantProps<typeof actionIconRootStyles> {
  children: React.ReactNode;
  label: string;
  loading?: boolean;
  withTooltip?: boolean;
  tooltipProps?: Partial<TooltipProps>;
}

export const ActionIcon = createPolymorphicComponent<'button', ActionIconProps>(
  forwardRef<HTMLButtonElement, ActionIconProps>(
    (
      {
        color = 'white',
        variant = 'plain',
        size = 'md',
        label,
        className,
        disabled = false,
        loading = false,
        withTooltip = true,
        tooltipProps = {},
        children,
        ...restProps
      },
      ref
    ) => {
      const Component = (
        <MantineActionIcon
          ref={ref}
          unstyled
          classNames={{
            root: actionIconRootStyles({
              variant,
              color: '#DFDCD4',
              size,
              disabled: disabled || loading,
              loading,
              className,
            }),
          }}
          disabled={disabled || loading}
          loading={loading}
          loaderProps={{
            color: 'blue',
            size: size === 'sm' ? 18 : 20,
          }}
          aria-label={label}
          {...restProps}
        >
          {children}
        </MantineActionIcon>
      );

      return withTooltip ? (
        <Tooltip label={label} {...tooltipProps}>
          {Component}
        </Tooltip>
      ) : (
        Component
      );
    }
  )
);
