// https://mantine.dev/core/number-input/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  NumberInput as MantineNumberInput,
  NumberInputProps as MantineNumberInputProps,
  NumberInputStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type NumberInputClassNamesType = Partial<Record<NumberInputStylesNames, string>>;

const styles: ClassArrayOrString<NumberInputClassNamesType> = {
  input: 'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100',
  label: 'font-semibold leading-[19px] text-andisor-navy-400',
};

export interface NumberInputProps extends MantineNumberInputProps {
  classNames?: NumberInputClassNamesType;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return (
      <MantineNumberInput
        ref={ref}
        classNames={mergedStyles}
        allowNegative={false}
        thousandSeparator=","
        autoComplete="off"
        hideControls
        {...restProps}
      />
    );
  }
);
