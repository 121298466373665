import { ChangeEvent } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type RHFCheckboxGroupProps = {
  name: string;
  items: {
    id: string;
    name: string;
  }[];
  valueKey?: 'id' | 'name';
  defaultValue?: string[];
};

const RHFCheckboxGroup = ({
  name,
  items,
  defaultValue,
  valueKey = 'id',
}: RHFCheckboxGroupProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          {items?.map(item => (
            <FormControlLabel
              key={item.id}
              label={item.name}
              disableTypography
              control={
                <Checkbox
                  {...field}
                  value={item[valueKey]}
                  checked={field.value.includes(item[valueKey])}
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    const inputVal = evt.target.value;
                    const selectedValues = evt.target.checked
                      ? [...field.value, inputVal]
                      : field.value.filter(value => value !== inputVal);
                    field.onChange(selectedValues);
                  }}
                  style={{ color: '#171BD4' }}
                  size="small"
                />
              }
            />
          ))}
        </>
      )}
    />
  );
};

export default RHFCheckboxGroup;
