// https://mantine.dev/core/native-select/

import { forwardRef } from 'react';
import {
  __InputStylesNames,
  // eslint-disable-next-line no-restricted-imports
  NativeSelect as MantineNativeSelect,
  NativeSelectProps as MantineNativeSelectProps,
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type NativeSelectClassNamesType = Partial<Record<__InputStylesNames, string>>;

const styles: ClassArrayOrString<NativeSelectClassNamesType> = {
  input: 'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100',
  label: 'font-semibold leading-[19px] text-andisor-navy-400',
};

export interface NativeSelectProps extends MantineNativeSelectProps {
  classNames?: NativeSelectClassNamesType;
}

export const NativeSelect = forwardRef<HTMLSelectElement, NativeSelectProps>(
  ({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return (
      <MantineNativeSelect
        ref={ref}
        classNames={mergedStyles}
        rightSection={<IconChevronDown width={20} height={20} />}
        {...restProps}
      />
    );
  }
);
