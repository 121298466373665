// https://mantine.dev/core/combobox/

import {
  // eslint-disable-next-line no-restricted-imports
  Combobox as MantineCombobox,
  ComboboxProps as MantineComboboxProps,
  ComboboxStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type ComboboxClassNamesType = Partial<Record<ComboboxStylesNames, string>>;

const styles: ClassArrayOrString<ComboboxClassNamesType> = {};

export interface ComboboxProps extends MantineComboboxProps {
  classNames?: ComboboxClassNamesType;
}

export const Combobox = ({ classNames, ...restProps }: ComboboxProps) => {
  const mergedStyles = mergeMantineStyles(styles, classNames);

  return <MantineCombobox classNames={mergedStyles} withinPortal={false} {...restProps} />;
};

Combobox.Target = MantineCombobox.Target;
Combobox.Dropdown = MantineCombobox.Dropdown;
Combobox.Options = MantineCombobox.Options;
Combobox.Option = MantineCombobox.Option;
Combobox.Empty = MantineCombobox.Empty;
/* misc */
Combobox.Header = MantineCombobox.Header;
Combobox.Footer = MantineCombobox.Footer;
Combobox.Group = MantineCombobox.Group;
Combobox.Chevron = MantineCombobox.Chevron;
Combobox.ClearButton = MantineCombobox.ClearButton;
Combobox.Search = MantineCombobox.Search;

// https://mantine.dev/core/combobox/#split-events-and-dropdown-targets
Combobox.DropdownTarget = MantineCombobox.DropdownTarget;
Combobox.EventsTarget = MantineCombobox.EventsTarget;

export { useCombobox, useVirtualizedCombobox } from '@mantine/core';
export type { ComboboxStore, UseComboboxOptions } from '@mantine/core';
