// https://mantine.dev/core/modal/

import {
  // eslint-disable-next-line no-restricted-imports
  Modal as MantineModal,
  ModalProps as MantineModalProps,
  ModalStylesNames,
} from '@mantine/core';
import { mergeMantineStyles } from '../../locals/utils';

export type ModalClassNamesType = Partial<Record<ModalStylesNames, string>>;

export const modalStyles: ModalClassNamesType = {
  overlay: 'bg-andisor-navy/50',
  header: 'pt-9 px-8 pb-0 flex justify-center',
  body: 'px-8 pt-6 pb-9',
  title: 'font-heading text-center text-2xl',
  close:
    'absolute top-7 right-7 text-andisor-navy focus:outline-none focus-within:outline-none focus:border-none',
};

export interface ModalProps extends Omit<MantineModalProps, 'opened' | 'onClose'> {
  onClose: () => void;
  opened: boolean | string;
  loading?: boolean;
  classNames?: ModalClassNamesType;
  overlayProps?: MantineModalProps['overlayProps'];
}

export const modalProps: Omit<MantineModalProps, 'opened' | 'onClose'> = {
  zIndex: 10000,
  size: 'lg',
  centered: true,
  overlayProps: {
    backgroundOpacity: 0.5,
    blur: 5,
  },
  returnFocus: false,
  classNames: modalStyles,
};

export const Modal = ({
  opened,
  onClose,
  classNames,
  overlayProps: overlayPropsOverride,
  loading = false,
  ...restProps
}: ModalProps) => {
  const mergedStyles = mergeMantineStyles(modalStyles, classNames);

  let overlayProps: MantineModalProps['overlayProps'] = modalProps.overlayProps;
  if (overlayPropsOverride) {
    overlayProps = {
      ...modalProps.overlayProps,
      ...overlayPropsOverride,
    };
  }

  return (
    <MantineModal
      {...modalProps}
      opened={Boolean(opened)}
      onClose={loading ? () => null : onClose}
      closeButtonProps={{ style: { opacity: loading ? 0.5 : 1 }, disabled: loading }}
      classNames={mergedStyles}
      overlayProps={overlayProps}
      {...restProps}
    />
  );
};
