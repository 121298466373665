// https://mantine.dev/core/input/
// https://react-international-phone.vercel.app/docs/Advanced%20Usage/useWithUiLibs
import { forwardRef } from 'react';
import {
  __InputStylesNames,
  // eslint-disable-next-line no-restricted-imports
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core';
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { IconChevronDown } from '@ui/icons';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';
import { cx } from '../../utils';
import { UnstyledButton } from '../Button';
import { Combobox, useCombobox } from '../Combobox';
import { ScrollArea } from '../ScrollArea';
import { Text } from '../Text';

type TextInputClassNamesType = Partial<Record<__InputStylesNames, string>>;

export const phoneNumberRegex = /^\+(?:\d ?){6,14}\d$/;

const styles: ClassArrayOrString<TextInputClassNamesType> = {
  input: 'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100 pl-12',
  label: 'font-semibold leading-[19px] text-andisor-navy-400',
  section: 'w-14 px-2',
};

export interface PhoneNumberInputProps extends Omit<MantineTextInputProps, 'onChange'> {
  classNames?: TextInputClassNamesType;
  disabled?: boolean;
  value: string;
  onChange: (phone: string) => void;
}

export const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  ({ classNames, value, disabled = false, onChange, ...restProps }, ref) => {
    const combobox = useCombobox();

    const { inputValue, handlePhoneValueChange, country, setCountry } = usePhoneInput({
      defaultCountry: 'au',
      value,
      countries: defaultCountries,
      onChange: data => {
        onChange(data.phone);
      },
    });

    const mergedStyles = mergeMantineStyles(styles, classNames);

    return (
      <MantineTextInput
        ref={ref}
        value={inputValue}
        onChange={handlePhoneValueChange}
        classNames={mergedStyles}
        autoComplete="off"
        disabled={disabled}
        leftSection={
          <Combobox
            store={combobox}
            onOptionSubmit={option => {
              setCountry(option);
              combobox.closeDropdown();
            }}
            disabled={disabled}
            offset={10}
            withArrow
          >
            <Combobox.Target>
              <UnstyledButton
                className={cx('flex w-14 items-center', disabled && 'cursor-not-allowed')}
                disabled={disabled}
                onClick={() => combobox.openDropdown()}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
              >
                <FlagImage iso2={country?.iso2} style={{ display: 'flex', width: '20px' }} />
                <IconChevronDown size={16} className="shrink-0" />
              </UnstyledButton>
            </Combobox.Target>
            <Combobox.Dropdown className="!w-60 text-andisor-navy">
              <Combobox.Options>
                <ScrollArea type="auto" h={150}>
                  {defaultCountries.map(c => {
                    const option = parseCountry(c);
                    return (
                      <Combobox.Option
                        key={option.iso2}
                        value={option.iso2}
                        className={cx(
                          'flex gap-2',
                          country.iso2 === option.iso2 && 'bg-andisor-gray'
                        )}
                      >
                        <FlagImage iso2={option.iso2} style={{ display: 'flex', width: '16px' }} />
                        <Text span size="c2">
                          {option.name}
                        </Text>
                        <Text span size="c2">
                          +{option.dialCode}
                        </Text>
                      </Combobox.Option>
                    );
                  })}
                </ScrollArea>
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        }
        {...restProps}
      />
    );
  }
);
