// https://mantine.dev/core/popover/

import {
  // eslint-disable-next-line no-restricted-imports
  Popover as MantinePopover,
  PopoverProps as MantinePopoverProps,
  PopoverStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type PopoverClassNamesType = Partial<Record<PopoverStylesNames, string>>;

const styles: ClassArrayOrString<PopoverClassNamesType> = {};

export interface PopoverProps extends MantinePopoverProps {
  classNames?: PopoverClassNamesType;
}

export const Popover = ({ classNames, ...restProps }: MantinePopoverProps) => {
  const mergedStyles = mergeMantineStyles(styles, classNames);

  return (
    <MantinePopover
      classNames={mergedStyles}
      position="bottom"
      withinPortal={false}
      withArrow
      {...restProps}
    />
  );
};

Popover.Target = MantinePopover.Target;
Popover.Dropdown = MantinePopover.Dropdown;
export type { PopoverTargetProps, PopoverDropdownProps, PopoverWidth } from '@mantine/core';
