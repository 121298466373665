// https://mantine.dev/core/loader/

// eslint-disable-next-line no-restricted-imports
import { Loader as MantineLoader, LoaderProps as MantineLoaderProps } from '@mantine/core';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

// TODO: Setting classname does not work, need to investigate
const defaultStyle: string = 'text-andisor-blue';

export interface LoaderProps extends MantineLoaderProps {
  className?: string;
}

export const Loader = ({ className, ...restProps }: LoaderProps) => {
  let style = defaultStyle;

  if (className) {
    style = twMerge(clsx(defaultStyle, className));
  }

  return <MantineLoader className={style} size="md" {...restProps} />;
};
