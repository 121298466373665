// eslint-disable-next-line no-restricted-imports
export * from '@tabler/icons-react'; // re-export all tabler icons (https://tabler-icons.io/)

// custom icons - ensure icons exported here don't clash with tabler icons!, we add 'Custom' to the name
export { default as IconCustomArrowRightMedium } from './ArrowRightMedium';
export { default as IconCustomArrowRightSmall } from './ArrowRightSmall';
export { default as IconCustomArrowLeftSmall } from './ArrowLeftSmall';
export { default as IconCustomLockSimple } from './LockSimple';
export { default as IconCustomQuote } from './Quote';
export { default as IconCustomAdjust } from './Adjust';
