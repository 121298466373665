import {
  // eslint-disable-next-line no-restricted-imports
  Breadcrumbs as MantineBreadCrumbs,
  BreadcrumbsProps as MantineBreadcrumbsProps,
  BreadcrumbsStylesNames,
} from '@mantine/core';
import ArrowLeftSmall from '../../icons/ArrowLeftSmall';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';
import { cx } from '../../utils';
import { UnstyledButton } from '../Button/UnstyledButton';

type BreadcrumbsClassNamesType = Partial<Record<BreadcrumbsStylesNames, string>>;

const styles: ClassArrayOrString<BreadcrumbsClassNamesType> = {
  breadcrumb: 'text-andisor-navy text-xl font-body',
  separator: 'text-xl mx-2',
};

export interface BreadcrumbsProps extends MantineBreadcrumbsProps {
  classNames?: BreadcrumbsClassNamesType;
  className?: string;
  backArrow?: boolean;
  onBackClick?: () => void;
  children: React.ReactNode;
}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { classNames, className, backArrow = true, onBackClick, children, ...restProps } = props;

  const mergedStyles = mergeMantineStyles(styles, classNames);

  return (
    <div className={cx('flex items-center gap-2', className)}>
      {backArrow && (
        <>
          {onBackClick ? (
            <UnstyledButton onClick={onBackClick}>
              <ArrowLeftSmall className="text-andisor-navy" />
            </UnstyledButton>
          ) : (
            <ArrowLeftSmall className="text-andisor-navy" />
          )}
        </>
      )}
      <MantineBreadCrumbs classNames={mergedStyles} separator="/" {...restProps}>
        {children}
      </MantineBreadCrumbs>
    </div>
  );
};
