// https://mantine.dev/core/progress/

import {
  // eslint-disable-next-line no-restricted-imports
  Progress as MantineProgress,
  ProgressProps as MantineProgressProps,
  ProgressStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type ProgressClassNamesType = Partial<Record<ProgressStylesNames, string>>;

const styles: ClassArrayOrString<ProgressClassNamesType> = {};

export interface ProgressProps extends MantineProgressProps {
  classNames?: ProgressClassNamesType;
}

export const Progress = ({ classNames, ...restProps }: MantineProgressProps) => {
  const mergedStyles = mergeMantineStyles(styles, classNames);

  return <MantineProgress classNames={mergedStyles} {...restProps} />;
};

Progress.Root = MantineProgress.Root;
Progress.Section = MantineProgress.Section;
Progress.Label = MantineProgress.Label;
