export const ICON_SIZE = {
  xl: 40,
  lg: 32,
  md: 24,
  sm: 20,
  xs: 16,
};

export const ICON_STROKE = {
  lg: 2,
  md: 1.5,
  sm: 1,
  xs: 0.5,
};
