import { ComponentProps } from 'react';
// creating resuable form component with zod and react-hook-form
// https://omkarkulkarni.vercel.app/blog/reusable-form-component-in-react-using-react-hook-form-and-zod
import { zodResolver } from '@hookform/resolvers/zod';
import {
  // typescript type of form's field values
  FieldValues,
  // context provider for our form
  FormProvider,
  // type of submit handler event
  SubmitHandler,
  // we import useForm hook as useHookForm
  useForm as useHookForm,
  // typescript types of useHookForm props
  UseFormProps as UseHookFormProps,
  // return type of useHookForm hook
  UseFormReturn,
  // hook that would return errors in current instance of form
  // useFormContext,
} from 'react-hook-form';
// Type of zod schema
import { TypeOf, ZodSchema } from 'zod';
import Input from '@components/ReactHookForm/Input';
import TextArea from '@components/ReactHookForm/TextArea';

interface UseFormProps<T extends ZodSchema<any>> extends UseHookFormProps<TypeOf<T>> {
  schema: T;
}

export const useForm = <T extends ZodSchema<any>>({ schema, ...formConfig }: UseFormProps<T>) => {
  return useHookForm({
    ...formConfig,
    resolver: zodResolver(schema),
  });
};

// form props extends the html tag form, but without the onSubmit
interface FormProps<T extends FieldValues = any>
  extends Omit<ComponentProps<'form'>, 'onSubmit' | 'className'> {
  form: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>;
  className?: string;
  formWrapperClassName?: string;
  disableForm?: boolean;
}

export const Form = <T extends FieldValues>({
  form,
  onSubmit,
  children,
  className,
  formWrapperClassName,
  disableForm,
  ...props
}: FormProps<T>) => {
  return (
    <FormProvider {...form}>
      {/* the `form` passed here is return value of useForm() hook */}
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={formWrapperClassName ?? ''}
        {...props}
      >
        <fieldset
          //  We disable form inputs when we are submitting the form A tiny detail
          //        that is missed a lot of times
          disabled={form.formState.isSubmitting || disableForm}
          className={className ?? ''}
        >
          {children}
        </fieldset>
      </form>
    </FormProvider>
  );
};

Form.Input = Input;
Form.TextArea = TextArea;
