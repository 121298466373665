// https://mantine.dev/core/segmented-control/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  SegmentedControl as MantineSegmentedControl,
  SegmentedControlProps as MantineSegmentedControlProps,
  SegmentedControlStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type SegmentedControlClassNamesType = Partial<Record<SegmentedControlStylesNames, string>>;

const styles: ClassArrayOrString<SegmentedControlClassNamesType> = {};

export interface SegmentedControlProps extends MantineSegmentedControlProps {
  classNames?: SegmentedControlClassNamesType;
}

export const SegmentedControl = forwardRef<HTMLDivElement, SegmentedControlProps>(
  ({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return <MantineSegmentedControl ref={ref} classNames={mergedStyles} {...restProps} />;
  }
);
