import { PURGE } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateDraftOrderPayloadType } from '@api/buyers/order';

type initialStateProps = {
  orderId: number | null;
  shouldAnimateCartIcon: boolean;
  draftOrderData: CreateDraftOrderPayloadType | null;
};
const initialState: initialStateProps = {
  orderId: null,
  shouldAnimateCartIcon: false,
  draftOrderData: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setOrderId: (state, action: PayloadAction<initialStateProps['orderId']>) => {
      state.orderId = action.payload;
    },
    animateCartIcon: (state, action: PayloadAction<initialStateProps['shouldAnimateCartIcon']>) => {
      state.shouldAnimateCartIcon = action.payload;
    },
    setDraftOrderData: (state, action) => {
      state.draftOrderData = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { setOrderId, animateCartIcon, setDraftOrderData } = cartSlice.actions;

export default cartSlice;
