import { PURGE } from 'redux-persist';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { NavigationTreeType } from '@api/common/browse';

export interface CommonState {
  navigationTrees: NavigationTreeData;
}

export interface NavigationTreeData {
  [key: string]: NavigationTree;
}

export interface NavigationTree {
  brandId: number;
  data: NavigationTreeType[];
  brandSlug: string;
  brandName: string;
}

const initialState: CommonState = {
  navigationTrees: {},
};

interface brandNavigationTreePayload {
  data: NavigationTreeType[];
  brandId: number;
  brandSlug: string;
  brandName: string;
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateBrandNavigationTree: (state, action: PayloadAction<brandNavigationTreePayload>) => {
      state.navigationTrees[action.payload.brandSlug] = {
        data: action.payload.data,
        brandId: action.payload.brandId,
        brandSlug: action.payload.brandSlug,
        brandName: action.payload.brandName,
      };
    },
  },

  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { updateBrandNavigationTree } = commonSlice.actions;

export default commonSlice;
