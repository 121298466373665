// https://mantine.dev/core/file-input/

import { forwardRef } from 'react';
import {
  __InputStylesNames,
  // eslint-disable-next-line no-restricted-imports
  FileInput as MantineFileInput,
  FileInputProps as MantineFileInputProps,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type FileInputClassNamesType = Partial<Record<__InputStylesNames, string>>;

const styles: ClassArrayOrString<FileInputClassNamesType> = {
  input: 'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100',
};

export interface FileInputProps extends MantineFileInputProps {
  classNames?: FileInputClassNamesType;
}

export const FileInput = forwardRef<HTMLButtonElement, FileInputProps>(
  ({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return <MantineFileInput ref={ref} classNames={mergedStyles} {...restProps} />;
  }
);
