import React from 'react';
// tooltip
import * as Tooltip from '@radix-ui/react-tooltip';

interface ToolTipProps {
  children: React.ReactNode;
  content: string | React.ReactNode;
  showArrow?: boolean;

  arrowClassNames?: string;

  showToolTipDelaySeconds?: number;
  disableHoverableContent?: boolean;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  arrowHeight?: number;
  arrowWidth?: number;
}

// radix dropdown documentation:
// https://www.radix-ui.com/docs/primitives/components/tooltip

// radix with tailwind animations:
// https://github.com/ecklf/tailwindcss-radix/blob/main/demo/components/tooltip.tsx

const ToolTip = ({
  children,
  content,

  // content
  showToolTipDelaySeconds = 0.15,
  disableHoverableContent = false,
  align = 'center',
  side = 'top',
  sideOffset = 4,

  // arrow
  arrowClassNames,
  showArrow = true,
}: ToolTipProps) => {
  return (
    <Tooltip.Provider
      delayDuration={showToolTipDelaySeconds * 1000}
      disableHoverableContent={disableHoverableContent}
    >
      <Tooltip.Root>
        <Tooltip.Portal>
          <Tooltip.Content
            sideOffset={sideOffset}
            side={side}
            align={align}
            className={
              (typeof content === 'string'
                ? `z-[900] flex items-center rounded-md bg-white px-2 py-1 shadow-md`
                : '') +
              `
                            radix-side-bottom:animate-slide-up-fade 
                            radix-side-left:animate-slide-right-fade 
                            radix-side-right:animate-slide-left-fade 
                            radix-side-top:animate-slide-down-fade
                            `
            }
          >
            {typeof content === 'string' ? (
              <span className="max-w-[200px] text-xs text-gray-700">{content}</span>
            ) : (
              content
            )}

            {showArrow && (
              <Tooltip.Arrow
                className={
                  arrowClassNames ? `fill-current ${arrowClassNames}` : 'fill-current text-white'
                }
              />
            )}
          </Tooltip.Content>
        </Tooltip.Portal>

        <Tooltip.Trigger asChild>
          {children}
          {/* <div className="h-fit w-fit">{children}</div> */}
        </Tooltip.Trigger>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default ToolTip;

// example tooltip
{
  /* <ToolTip content={ttText ? "hello" : "This is some random longer text"}>
    <Button
        id="hi"
        text="hello"
        size="md"
        ariaLabel="hello"
        onClick={() => setTtText(!ttText)}
        focusRingClassName="ring-blue-500 ring-offset-slate-800"
        className={({ isPressed }) =>
            `${
                isPressed ? "brightness-90" : "hover:brightness-110"
            } bg-blue-500`
        }
    />
</ToolTip>; */
}

// you can also change the content based on state, will automatically change
