// https://mantine.dev/core/tabs/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Tabs as MantineTabs,
  type TabsProps as MantineTabsProps,
  TabsStylesNames,
} from '@mantine/core';
import { tv, type VariantProps } from 'tailwind-variants';
import { ClassArrayOrString } from '../../locals/types';

type TabsClassNamesType = Partial<Record<TabsStylesNames, string>>;

const styles = tv({
  slots: {
    list: '',
    tab: '',
    tabLabel: 'text-base',
  } as ClassArrayOrString<TabsClassNamesType>,
  variants: {
    variant: {
      default: {
        list: 'gap-4 before:border-andisor-gray',
        tab: [
          'p-0 pb-2',
          'data-[active=true]:border-b-[1px] data-[active=true]:border-andisor-red',
        ],
      },
      plain: {
        list: 'gap-10 before:border-transparent mobile:gap-6',
        tab: [
          'hover:border-transparent',
          'p-0 pb-2',
          'data-[active=true]:border-b-[1px] data-[active=true]:border-andisor-red',
        ],
      },
      rounded: {
        list: 'gap-2 before:border-transparent',
        tab: [
          'hover:border-transparent',
          'flex items-center rounded-md px-2 py-0.5 text-sm',
          'data-[active=true]:border-none data-[active=true]:border-andisor-blue data-[active=true]:bg-white data-[active=true]:text-andisor-navy data-[active=true]:shadow-md',
        ],
        tabLabel: 'text-sm',
      },
    },
    color: {
      red: '',
      blue: {
        list: 'before:content-none',
        tab: [
          'px-1 pb-[12.5px] pt-[0.5px]',
          'font-semibold text-andisor-gray-600 data-[active=true]:border-b-[2px] data-[active=true]:border-andisor-blue data-[active=true]:text-andisor-blue',
        ],
        tabLabel: 'text-sm',
      },
    },
  },
  defaultVariants: {
    variant: 'default',
    color: 'red',
  },
});

export interface TabsProps
  extends Omit<MantineTabsProps, 'variant' | 'color'>,
    VariantProps<typeof styles> {
  classNames?: TabsClassNamesType;
}

export const Tabs = Object.assign(
  forwardRef<HTMLInputElement, TabsProps>(
    ({ classNames, variant = 'default', color = 'red', ...restProps }: TabsProps, ref) => {
      const { list, tab, tabLabel } = styles({ variant, color });
      return (
        <MantineTabs
          ref={ref}
          classNames={{
            ...classNames,
            list: list?.({ class: classNames?.list }),
            tab: tab?.({ class: classNames?.tab }),
            tabLabel: tabLabel?.({ class: classNames?.tabLabel }),
          }}
          activateTabWithKeyboard={false}
          keepMounted={false}
          {...restProps}
        />
      );
    }
  ),
  {
    Tab: MantineTabs.Tab,
    List: MantineTabs.List,
    Panel: MantineTabs.Panel,
  }
);

export type { TabsTabProps, TabsListProps, TabsPanelProps } from '@mantine/core';

/*
<Tabs defaultValue="brands" variant="plain">
  <Tabs.List>
    <Tabs.Tab value="brands">Brands</Tabs.Tab>
    <Tabs.Tab value="products">Products</Tabs.Tab>
    <Tabs.Tab value="collections">Collections</Tabs.Tab>
  </Tabs.List>
  <Tabs.Panel value="brands">Brands content</Tabs.Panel>
  <Tabs.Panel value="products">Products content</Tabs.Panel>
  <Tabs.Panel value="collections">Collections content</Tabs.Panel>
</Tabs>
*/
