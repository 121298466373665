// https://mantine.dev/core/text-input/

import { forwardRef } from 'react';
import {
  __InputStylesNames,
  // eslint-disable-next-line no-restricted-imports
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type TextInputClassNamesType = Partial<Record<__InputStylesNames, string>>;

const styles: ClassArrayOrString<TextInputClassNamesType> = {
  input: 'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100',
  label: 'font-semibold leading-[19px] text-andisor-navy-400',
};

export interface TextInputProps extends MantineTextInputProps {
  classNames?: TextInputClassNamesType;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ classNames, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    return (
      <MantineTextInput ref={ref} classNames={mergedStyles} autoComplete="off" {...restProps} />
    );
  }
);
