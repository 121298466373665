// https://mantine.dev/core/radio/

import { forwardRef, useContext } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Radio as MantineRadio,
  RadioProps as MantineRadioProps,
  RadioStylesNames,
} from '@mantine/core';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';
import { RadioGroup, RadioGroupContext } from './RadioGroup';

type RadioClassNamesType = Partial<Record<RadioStylesNames, string>>;

const styles: ClassArrayOrString<RadioClassNamesType> = {
  body: 'flex items-center',
  radio: [
    'peer bg-white border-andisor-navy',
    'hover:enabled:border-2 hover:enabled:border-andisor-blue hover:enabled:checked:border hover:enabled:checked:border-andisor-navy',
    'enabled:cursor-pointer',
    'disabled:border-andisor-gray-500 disabled:cursor-not-allowed',
  ],
  icon: 'text-andisor-navy peer-disabled:text-andisor-gray-500',
  label: [
    'text-base font-body select-none cursor-pointer',
    'data-[disabled=true]:text-andisor-gray-500 data-[disabled=true]:cursor-not-allowed',
  ],
};

export interface RadioProps extends MantineRadioProps {
  classNames?: RadioClassNamesType;
  disabled?: boolean;
}

export const Radio = Object.assign(
  forwardRef<HTMLInputElement, RadioProps>(
    ({ classNames, disabled = false, ...restProps }, ref) => {
      const mergedStyles = mergeMantineStyles(styles, classNames);

      const { disabled: groupDisabled } = useContext(RadioGroupContext);

      return (
        <MantineRadio
          ref={ref}
          classNames={mergedStyles}
          style={{
            '--radio-size': '1.125rem',
            '--radio-icon-size': '0.75rem',
          }}
          disabled={groupDisabled || disabled}
          {...restProps}
        />
      );
    }
  ),
  {
    Group: RadioGroup,
  }
);
