// https://mantine.dev/core/text/

import { forwardRef, type ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Text as MantineText } from '@mantine/core';
import { tv, type VariantProps } from 'tailwind-variants';

const styles = tv({
  base: 'inline-block font-body',
  variants: {
    variant: {
      plain: '',
      outline: 'border-b border-andisor-red pb-2',
    },
    size: {
      s1: 'text-xl', // subheading-1 /* 20px, 26px */
      s2: 'text-2xl', // subheading-2 /* 24px, 30px */
      s3: 'text-3xl', // subheading-3 /* 30px, 36px */
      p2: 'text-lg', // paragraph-2  /* 18px, 24px */
      p1: 'text-base', // paragraph-1 /* 16px, 22px */
      c1: 'text-sm', // caption-1 /* 14px, 19px */
      c2: 'text-xs', // caption-2 /* 12px, 17px */
    },
  },
  defaultVariants: {
    variant: 'plain',
    size: 'p1',
  },
});

export interface TextProps
  extends React.HTMLAttributes<HTMLParagraphElement | HTMLSpanElement>,
    VariantProps<typeof styles> {
  children: ReactNode;
  span?: boolean;
  lineClamp?: number;
  truncate?: boolean;
  className?: string;
}

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  (
    {
      variant = 'plain',
      size = 'p1',
      children,
      span,
      lineClamp,
      truncate,
      className,
      ...restProps
    },
    ref
  ) => {
    return (
      <MantineText
        ref={ref}
        className={styles({ variant, size, className })}
        component={span ? 'span' : 'p'}
        lineClamp={lineClamp}
        truncate={truncate}
        {...restProps}
      >
        {children}
      </MantineText>
    );
  }
);
