// https://mantine.dev/core/button/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  createPolymorphicComponent,
} from '@mantine/core';
import { type VariantProps } from 'tailwind-variants';
import { cx } from '@ui/utils';
import { IconCustomArrowRightMedium, IconCustomArrowRightSmall } from '../../icons';
import { buttonRootStyles } from './styles';

export type ButtonStylesType = VariantProps<typeof buttonRootStyles>;

export interface ButtonProps
  extends Omit<MantineButtonProps, 'size' | 'variant' | 'color'>,
    ButtonStylesType {
  children?: React.ReactNode;
  loading?: boolean;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  leftArrow?: boolean;
  rightArrow?: boolean;
  labelFullWidth?: boolean;
}

const getArrowIcon = (size: ButtonStylesType['size']) => {
  return size === 'sm' ? <IconCustomArrowRightSmall /> : <IconCustomArrowRightMedium />;
};

export const Button = createPolymorphicComponent<'button', ButtonProps>(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        color = 'blue',
        variant = 'filled',
        size = 'md',
        className,
        disabled = false,
        loading = false,
        leftSection,
        rightSection,
        leftArrow,
        rightArrow,
        children,
        labelFullWidth = false,
        ...restProps
      },
      ref
    ) => {
      return (
        <MantineButton
          ref={ref}
          unstyled
          classNames={{
            root: buttonRootStyles({
              color,
              variant,
              size,
              disabled: disabled || loading,
              loading,
              className,
            }),
            inner: cx(
              'inline-flex items-center justify-center gap-2 whitespace-nowrap',
              labelFullWidth && 'w-full'
            ),
            loader: 'inline-flex justify-center items-center absolute w-full inset-0',
            label: cx(labelFullWidth && 'flex-1'),
          }}
          leftSection={leftArrow ? getArrowIcon(size) : leftSection}
          rightSection={rightArrow ? getArrowIcon(size) : rightSection}
          disabled={disabled || loading}
          loading={loading}
          loaderProps={{
            color: 'blue',
            size: size === 'xs' ? 18 : size === 'sm' ? 20 : 22,
          }}
          {...restProps}
        >
          {children}
        </MantineButton>
      );
    }
  )
);
