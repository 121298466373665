const ArrowRightMedium = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="-0.000488281"
        y1="12.0079"
        x2="24.9996"
        y2="12.0079"
        stroke="currentColor"
        strokeWidth="1.18421"
      />
      <path
        d="M25.5837 11.1054L25.5837 12.8949L16.7837 17.0001L16.7837 15.3159L24.089 12.0317L16.7837 8.74749L16.7837 7.00012L25.5837 11.1054Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRightMedium;
