export const formatAmount = (amount: number) => {
  return amount?.toLocaleString('en-AU', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const getCurrencySymbol = () => {
  return process.env.NEXT_PUBLIC_CURRENCY_SYMBOL
    ? `${process.env.NEXT_PUBLIC_CURRENCY_SYMBOL}`
    : '$';
};
