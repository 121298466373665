import { useMutation } from '@tanstack/react-query';
import { Controller, FormProvider } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { NumberInput, Textarea, TextInput } from '@ui/core';
import LoadingSpinner from '@components/LoadingSpinner';
import { useForm } from '@components/ReactHookForm/Form';
import RHFCheckboxGroup from '@components/ReactHookForm/RHFCheckboxGroup/RHFCheckboxGroup';
import RHFRadioGroup from '@components/ReactHookForm/RHFRadioGroup/RHFRadioGroup';
import { useAuth } from '@hooks/useAuth';
import useHubspot from '@hooks/useHubspot';
import { ROLES } from '@constants';

type HelpRequestModalProps = {
  closeModal: () => void;
};

const FormSchema = z.object({
  firstName: z.string().trim().min(1, { message: '* First name is required' }),
  lastName: z.string().trim().min(1, { message: '* Last name is required' }),
  email: z.string().trim().email({ message: '* Invalid email address' }),
  role: z.string().min(1, { message: '* Select a role' }),
  companyName: z.string().trim().min(1, { message: '* Company name is required' }),
  contactNumber: z
    .string()
    .trim()
    .refine(value => value.length === 10 || value.length === 0, {
      message: '* Invalid contact number',
    }),
  helpOption: z.array(z.string()).min(1, { message: '* Select at least one option' }),
  description: z.string().trim().min(1, { message: '* Description is required' }),
});

const ROLE_OPTIONS = [
  { id: 1, name: 'Retailer' },
  { id: 2, name: 'Brand' },
];

const HELP_OPTIONS = [
  { id: '1', name: 'My Account' },
  { id: '2', name: 'Orders & Shipping' },
  { id: '3', name: 'Invoices & Payments' },
  { id: '4', name: 'General Enquiry' },
  { id: '5', name: 'Other' },
];

const HelpRequestModal = ({ closeModal }: HelpRequestModalProps) => {
  const { raiseHelpRequest } = useHubspot();
  const { user } = useAuth();

  const form = useForm({
    schema: FormSchema,
    defaultValues: {
      helpOption: [],
      description: '',
    },
    mode: 'onBlur',
  });

  const raiseHelpRequestMutation = useMutation({
    mutationFn: (data: any) => {
      return raiseHelpRequest(data);
    },
    onSuccess: () => {
      toast.success('Support request sent successfully');
      closeModal();
    },
    onError: () => {
      toast.error('Something went wrong. Please try again later');
    },
  });

  const handleSubmit = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      const values = form.getValues();
      raiseHelpRequestMutation.mutate({
        ...values,
        helpOption: values.helpOption.join(', '),
      });
    }
  };

  return (
    <div className="flex flex-col">
      <p className="text-sm">Please provide some information below so we can best help you out.</p>
      <div className="mt-8 flex flex-1 flex-col justify-between">
        <div>
          <div className="grid grid-cols-2 gap-x-8 gap-y-4">
            <Controller
              name="firstName"
              control={form.control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="First Name"
                  value={field.value}
                  onChange={evt => {
                    field.onChange(evt.currentTarget.value);
                  }}
                  error={form.formState.errors?.firstName?.message}
                  withAsterisk
                />
              )}
              defaultValue={user?.firstName ?? ''}
            />
            <Controller
              name="lastName"
              control={form.control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="Last Name"
                  value={field.value}
                  onChange={evt => {
                    field.onChange(evt.currentTarget.value);
                  }}
                  error={form.formState.errors?.lastName?.message}
                  withAsterisk
                />
              )}
              defaultValue={user?.lastName ?? ''}
            />
            <Controller
              name="companyName"
              control={form.control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="Company Name"
                  value={field.value}
                  onChange={evt => {
                    field.onChange(evt.currentTarget.value);
                  }}
                  error={form.formState.errors?.companyName?.message}
                  withAsterisk
                />
              )}
              defaultValue={
                user?.role === ROLES.BRAND
                  ? user?.activeBrand?.name
                  : user?.activeRetailer?.name ?? ''
              }
            />
            <div className="flex flex-col text-sm">
              <label htmlFor="role" className="font-semibold">
                Select user type <span className="text-andisor-red">*</span>
              </label>
              <div className="flex mobile:flex-col">
                <FormProvider {...form}>
                  <RHFRadioGroup
                    name="role"
                    items={ROLE_OPTIONS}
                    valueKey="name"
                    defaultValue={
                      user?.role
                        ? user.role === ROLES.BRAND
                          ? 'Brand'
                          : user.role === ROLES.RETAILER
                            ? 'Retailer'
                            : ''
                        : ''
                    }
                  />
                </FormProvider>
              </div>
              {form.formState?.errors?.role && (
                <p className="text-xs text-andisor-red">{form.formState?.errors?.role?.message}</p>
              )}
            </div>
            <Controller
              name="email"
              control={form.control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="Email"
                  value={field.value}
                  onChange={evt => {
                    field.onChange(evt.currentTarget.value);
                  }}
                  error={form.formState.errors?.email?.message}
                  withAsterisk
                />
              )}
              defaultValue={user?.email ?? ''}
            />
            <Controller
              name="contactNumber"
              control={form.control}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  label="Contact Number"
                  placeholder="Optional - if you prefer us to call you"
                  value={field.value}
                  onChange={v => {
                    field.onChange(`${v}`);
                  }}
                  error={form.formState.errors?.contactNumber?.message}
                  isAllowed={inputVal => inputVal.value !== '-'}
                  valueIsNumericString={true}
                  allowDecimal={false}
                />
              )}
              defaultValue={user?.contactNumber ?? ''}
            />
          </div>
          <div>
            <div className="mt-4">
              <div className="flex flex-col text-sm">
                <label htmlFor="helpOption" className="font-semibold">
                  What can we help you with? <span className="text-andisor-red">*</span>
                </label>
                <FormProvider {...form}>
                  <RHFCheckboxGroup name="helpOption" items={HELP_OPTIONS} valueKey="name" />
                </FormProvider>
                {form.formState?.errors?.helpOption && (
                  <p className="text-xs text-andisor-red">
                    {form.formState.errors.helpOption.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <Controller
              name="description"
              control={form.control}
              render={({ field }) => (
                <Textarea
                  {...field}
                  label="Description"
                  placeholder="Describe the issue you'd like help with"
                  value={field.value}
                  onChange={evt => {
                    field.onChange(evt.currentTarget.value);
                  }}
                  error={form.formState.errors?.description?.message}
                  withAsterisk
                />
              )}
            />
          </div>
        </div>
        <div className="mx-10 mt-4 flex justify-center">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={raiseHelpRequestMutation.isLoading}
            className="flex gap-2 rounded-full bg-andisor-blue px-6 py-3 font-bold text-white transition-colors hover:bg-andisor-blue/90 disabled:cursor-not-allowed disabled:bg-andisor-gray"
          >
            <span>Submit</span>
            {raiseHelpRequestMutation.isLoading && <LoadingSpinner />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HelpRequestModal;
